import { createRouter, createWebHistory } from 'vue-router';
import auth from '../middleware/auth';

import HomeView from '../views/HomeView.vue';
import ViewersHomeView from '../views/viewers/ViewersHomeView';

import CallendarView from '../views/CallendarView.vue';
import ViewersCallendarView from '../views/viewers/ViewersCallendarView.vue';

import MedicalTestView from '../views/MedicalTestView.vue';
import MedicalTestAddView from '../views/MedicalTestAddView.vue';
import ViewersMedicalTestView from '../views/viewers/ViewersMedicalTestView.vue';


import MedicalTestEditView from '../views/MedicalTestEditView.vue';
import AppointmentsAddView from '../views/AppointmentsAddView.vue';
import AppointmentsEditView from '../views/AppointmentsEditView.vue';
import AppointmentsView from '../views/AppointmentsView.vue';
import ViewersAppointmentsView from '../views/viewers/ViewersAppointmentsView.vue';

import RegisterView from '../views/RegisterView.vue';
import LoginView from '../views/LoginView.vue';
import RegisterAuthView from '../views/RegisterAuthView.vue';
import ResetPassView from '../views/ResetPassView.vue';
import ResetPassCodeView from '../views/ResetPassCodeView.vue';
import TakeMedicineView from '../views/TakeMedicineView.vue';
import ViewersTakeMedicineView from '../views/viewers/ViewersTakeMedicineView.vue';


import TakeMedicineAddView from '../views/TakeMedicineAddView.vue';
import TakeMedicineEditView from '../views/TakeMedicineEditView.vue';
import MyAccountView from '../views/MyAccountView.vue';
import ViewersMyAccountView from '../views/viewers/ViewersMyAccountView.vue';


import AdminHomeView from '../views/admin/AdminHomeView.vue';
import AdminDrugsAddView from '../views/admin/AdminDrugsAddView.vue';
import AdminDrugsEditView from '../views/admin/AdminDrugsEditView.vue';
import AdminEditUserAccount from '../views/admin/AdminEditUserAccount.vue';
import PlaceAddView from '../views/PlaceAddView.vue';
import PlaceEditView from '../views/PlaceEditView.vue';
import PlacesView from '../views/PlacesView.vue';
import ExportView from '../views/ExportView.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: { formFooter: false, },

  },
  {
    path: '/kalendarz',
    name: 'calendar',
    component: CallendarView,
    props: { formFooter: false, },
  
  },

  {
    path: '/badania',
    name: 'medicalTest',
    component: MedicalTestView,
     props: { formFooter: false, },
  
  },
  
  {
    path: '/badania/dodaj',
    name: 'medicalTestAdd',
    component: MedicalTestAddView,
     props: { formFooter: true, },
  
  },

  {
    path: '/badania/edytuj/:id',
    name: 'medicalTestEdit',
    component: MedicalTestEditView,
     props: { formFooter: true, },
  
  },
  // {
  //   path: '/wizyty',
  //   name: 'appointmentsAdd',
  //   component: AppointmentsView,
  //    props: { formFooter: false, },
  
  // },
  {
    path: '/wizyty',
    name: 'appointments',
    component: AppointmentsView,
     props: { formFooter: false, },
  
  },
{
    path: '/wizyty/dodaj',
    name: 'appointmentsAdd',
    component: AppointmentsAddView,
     props: { formFooter: true, },
  
  },

  {
    path: '/wizyty/edytuj/:id',
    name: 'appointmentsEdit',
    component: AppointmentsEditView,
     props: { formFooter: true, },
  
  },

  {
    path: '/moje-konto',
    name: 'myAccount',
    component: MyAccountView,
     props: { formFooter: false, },
  
  },

  {
    path: '/podanie-leku',
    name: 'takeMedicine',
    component: TakeMedicineView,
     props: { formFooter: false, },
  },



  {
    path: '/podanie-leku/dodaj',
    name: 'takeMedicineWiew',
    component: TakeMedicineAddView,
     props: { formFooter: true, },
  },
  {
    path: '/podanie-leku/edytuj/:id',
    name: 'EditTakeMedicine',
    component: TakeMedicineEditView,
     props: { formFooter: true, },
  },
  {
    path: '/rejestracja',
    name: 'register',
    component: RegisterView,
     props: { formFooter: false, },

  },
  {
    path: '/logowanie',
    name: 'login',
    component: LoginView,
     props: { formFooter: false, },
 
  },
  {
    path: '/odzyskaj-haslo',
    name: 'resetPassView',
    component: ResetPassView,
     props: { formFooter: false, },
 
  },
  {
    path: '/odzyskaj-haslo-kod',
    name: 'resetPassCodeView',
    component:   ResetPassCodeView,
     props: { formFooter: false, },
 
  },

  {
    path: '/autoryzacja',
    name: 'registerAuth',
    component: RegisterAuthView,
     props: { formFooter: false, },
 
  },
  {
    path: '/admin',
    name: 'adminHome',
    component: AdminHomeView,
     props: { formFooter: false, },
 
  },

  {
    path: '/admin/leki/dodaj',
    name: 'adminDrugsAdd',
    component: AdminDrugsAddView,
     props: { formFooter: true, },
  
  },
  {
    path: '/admin/edytuj-profil/:id',
    name: 'adminProfileEdit',
    component: AdminEditUserAccount,
     props: { formFooter: false, },
  
  },
  {
    path: '/admin/leki/edytuj/:id',
    name: 'adminDrugsEdit',
    component: AdminDrugsEditView,
     props: { formFooter: true, },
  },

  {
    path: '/placowki',
    name: 'placesList',
    component: PlacesView,
     props: { formFooter: false, },
  
  },
  {
    path: '/placowki/dodaj',
    name: 'placeAdd',
    component: PlaceAddView,
     props: { formFooter: true, },
  
  },

  {
    path: '/placowki/edytuj/:id',
    name: 'placeEdit',
    component: PlaceEditView,
     props: { formFooter: true, },
  },

  {
    path: '/eksport',
    name: 'exportView',
    component: ExportView,
     props: { formFooter: false, },
  },


  {
    path: '/podglad/:uId',
    name: 'viewersHome',
    component: ViewersHomeView,
    props: { formFooter: false, },

  },
  {
    path: '/podglad/:uId/kalendarz',
    name: 'viewersCallendarView',
    component:   ViewersCallendarView,
    props: { formFooter: false, },

  },
  {
    path: '/podglad/:uId/wizyty',
    name: 'viewersAppointments',
    component: ViewersAppointmentsView,
     props: { formFooter: false, },
  
  },
  {
    path: '/podglad/:uId/podanie-leku',
    name: 'viewersTakeMedicine',
    component:   ViewersTakeMedicineView,
     props: { formFooter: false, },
  },

  {
    path: '/podglad/:uId/badania',
    name: 'viewers/MedicalTest',
    component: ViewersMedicalTestView,
     props: { formFooter: false, },
  },
  
  {
    path: '/podglad/:uId/dane-pacjenta',
    name: 'viewers/patientProfile',
    component: ViewersMyAccountView,
     props: { formFooter: false, },
  },
  
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  // if (to.name !== 'login' && to.name !== 'register' && !localStorage.getItem('token') && to.name !== 'registerAuth' && to.name !== 'resetPassView'  && to.name !== 'resetPassCodeView') {
  //   return '/logowanie'
  // }

  if(to.path.includes('admin') || from.path.includes('admin')){
   
    var isAdmin = localStorage.getItem('isAdmin');
 
    if(isAdmin == 'false'){
      return '/';
    }
    
  }

})

export default router
