
<template>
  <Topbar title="Placówki"/>

  <div class="app__content__wrapper">
    <div class="row">

      <div class="col-md-6"> 
        <Box title="Lista placówek">
          <table class="base-table" v-show="placeList.length">

            <thead>
              <th class="name">Nazwa placówki</th>
              <th v-if="false">Typ</th>
              <th class="action">Akcja</th>
            </thead>

            <tbody>
              <tr v-for="(place, index) in placeList" :key="index">
                <td class="name" data-name="Nazwa">
                  {{ place.name }}
                </td>
                <td class="action" data-name="Akcja">
                  <div class="action__wrapper">
                    <router-link :to="`/placowki/edytuj/${place.id}`" class="btn btn--primary  btn--circle  edit-drug"><img :src="editIcon"></router-link>
                    <button @click="onRemovePlace(place.id)" class="btn btn--secondary btn--circle remove-drug">X</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <router-link to="/placowki/dodaj" class="btn btn--primary btn--large">Dodaj placówkę</router-link>
          </div>
          
        </Box>

      </div>
    </div>
  </div>

</template>

<script>
import { format, addMinutes } from 'date-fns';
import Topbar from '@/components/Topbar.vue';
import FormFooter from '@/components/FormFooter.vue';
import TSelect from '@/components/form/TSelect.vue';
import TCheckbox from '@/components/form/TCheckbox.vue';
import TCheckboxes from '@/components/form/TCheckboxes.vue';
import TRadios from '@/components/form/TRadios.vue';
import Box from '@/components/Box.vue';
import {ref, inject, onMounted, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useFooterGap} from '@/hooks/useFooterGap';
import  edit_icon from '@/assets/edit_icon.svg' ;
export default {
name: 'PlacesView',
components: {
  Topbar,TSelect,TCheckbox,Box,TCheckboxes,TRadios,FormFooter
},
props: {
    formFooter: Boolean,
  },
emit: ['alert'],
 setup(props,{emit}) {
  useFooterGap(props.formFooter,emit);
  const axios = inject('axios');
  const router = useRouter();
  let drugsList = ref([]);
  const editIcon = ref(edit_icon);
  let placeList = ref([]);
     onMounted( async() => {
      await   getData() 

    });

    async function getData() {
 

      await axios.get('/patient-medical-facilites')
      .then(response => {

        placeList.value = response.data.data.map(drug =>{
          return {
            id: drug.id,
            name: drug.name,
          }
        }); 
      });
    }


    async function onRemovePlace(id){
      await axios.delete(`/patient-medical-facilites/${id}`)
      .then( async response => {
        await   getData() ;

        emit('alert',{
              type: 'success',
              title: 'Placówka została usunięta z systemu',
              visible: true,
              autoClose: true,
              data: []
            } );
        
      });
    }
  return {drugsList,placeList,onRemovePlace,editIcon };
},
}
</script>


<style lang="scss" scoped>



</style>