<template>

  <div class="t-checkboxes" :class="[className, {'t-checkboxes--inline': typeof inline !== 'undefined'}]"  v-show="typeof data !== 'undefined'">

    <div class="t-checkbox" :class="className"  v-show="typeof item.value !== 'undefined'" v-for="(item,index) in data" :key="index">
      <input type="checkbox" v-model="model" :name="name+'[]'" :id="id+'_'+index"  :value="item.value"   :class="{isLarge: typeof isLarge != 'undefined' ? isLarge : false}"  />
      <label :for="id+'_'+index" v-html="item.text">
       
      </label>
  </div>
  </div>
</template>

<script>
import {watch,ref} from 'vue';
import uniqid from 'uniqid';
import TCheckbox from '@/components/form/TCheckbox.vue';
export default {
  name: 'TCheckboxes',
  components:{
    TCheckbox
  },
  props: {
    name: String,
    data: Array,
    inline: String,
    id: String,
    className: String,
    modelValue: Object,
    isLarge: Boolean,
  },
  emit: ['update:modelValue'],
  setup(props, {emit} ){
    let model = ref(props.modelValue);
    const id = uniqid();
 
    watch(model, newVal => {
      
      emit('update:modelValue', newVal);
    });
    watch(()=> props.modelValue, newVal => {

      model.value =  newVal;
    });

    return {model,id};
  },

 
}
</script>


<style lang="scss" scoped>
.t-checkboxes{
  $gap: 10px;
  &--inline{
    
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gap;
    margin-left: -$gap;

    .t-checkbox{
      padding-left: $gap;
      padding-right: $gap;
      margin-bottom: $gap;
    }
  }
}
  
.t-checkbox{
  margin-bottom: 10px;
  input{
   
    opacity: 0;
    display: none;
    ~label{
      position: relative;
      padding-left: 1.8em;
      cursor: pointer;
      font-size: 14px;
      display: inline-block;
      &::before{
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 1.2em;
        height: 1.2em;
        border: 1px solid $border_color;
        display: inline-block;
        
      }
    }

    &.isLarge{
      ~label{
        font-size: 16px;
        padding-left: 2.2em;
        // margin-bottom: 20px;
        &::before{
          width: 1.7em;
          height: 1.7em;
          top: 3px;
        }
      }
    }
    &:checked{
      ~label{
        &::before{
          background-image: url('@/assets/checkbox.svg');
          background-size: 90%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
 
  }
  
}
  
</style>