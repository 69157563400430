
<template>
    <Topbar title="Nowa placówka"/>
 
    <div class="app__content__wrapper">
      <div class="row">
     <div class="col-md-6">
        <Box title="Dodaj placówkę">

          <form action="" @submit="onFormSubmit">
      
                <div class="form-field">
                  <label class="form-label"  for="">Tytuł</label>
                  <input  v-model="title"  cols="30" rows="10"/>
                </div>
             

                <div class="text-right">
                  <button type="submit" class="btn btn--primary btn--large">Dodaj placówkę</button>
                </div>
             
           
          
      
    
        </form>
        </Box>
      </div>
     </div>

    </div>

</template>

<script>
import { format, addMinutes } from 'date-fns';
import Topbar from '@/components/Topbar.vue';
import FormFooter from '@/components/FormFooter.vue';
import TSelect from '@/components/form/TSelect.vue';
import TCheckbox from '@/components/form/TCheckbox.vue';
import TCheckboxes from '@/components/form/TCheckboxes.vue';
import TRadios from '@/components/form/TRadios.vue';
import Box from '@/components/Box.vue';
import {ref, inject, onMounted, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useFooterGap} from '@/hooks/useFooterGap';
export default {
  name: 'PlaceEditView',
  components: {
    Topbar,TSelect,TCheckbox,Box,TCheckboxes,TRadios,FormFooter
  },
  props: {
    formFooter: Boolean,
  },
  emit: ['alert'],
   setup(props,{emit}) {
    useFooterGap(props.formFooter,emit);
    const axios = inject('axios');
    const router = useRouter();
    const route = useRoute();
    const footerHeight = ref(100);

    let title = ref('');
  

  
  async function onFormSubmit(evt){
    evt.preventDefault();
    let data = {
      "name": title.value,
     
    }
 
    await axios.post(`/patient-medical-facilites`,data)
      .then(response => {
        emit('alert',{
                type: 'success',
                title: 'Placówka została dodana!',
                visible: true,
                autoClose: true,
                data: []
              } );
        router.push({ path: '/placowki'});
      })
      .catch(err => {
      
          emit('alert',{
                type: 'error',
                title: 'Wystąpił problem z dodaniem placówki',
                data: [err.response.data.error.message],
                visible: true,
           } );
      });
      
    
   }
   
  
    return {footerHeight,title,onFormSubmit};
  },
}
</script>


<style lang="scss" scoped>
  

</style>