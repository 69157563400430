<template>
 <div  class="sidebox-content sidebox-content--type-chart" >
   {{ data }}
 </div>
</template>

<script>
import {ref,watch} from 'vue';
export default {
  name: 'SideboxContentChart',
  props: {
    data: Object,
    
  },

  setup(props,{emit}){

    return {};
  }
}
</script>


<style lang="scss" scoped>

</style>