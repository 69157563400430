
<template>
    <Topbar title="Edycja profilu"/>
 
    <div class="app__content__wrapper" >
      <div class="row">

      <div class="col-md-8">
        <Box title="Dane personalne">
          <form action="" @submit="onFormSubmit">
            <div class="row">
              <div class="col-md-7">
              
                  <div class="form-field">
                    <label class="form-label"  for="">Imię</label>
                    <input type="text"  v-model="firstName"  cols="30" rows="10"/>
                  </div>
                
                  <div class="form-field">
                    <label class="form-label"  for="">Nazwisko</label>
                    <input type="text"  v-model="lastName"  cols="30" rows="10"/>
                  </div>

              
                  
                  <div class="form-field">
                    <label class="form-label"  for="">Data urodzenia</label>
                    <div class="row">
                      <div class="col-md-4">
                        <TSelect setupText="Dzień" v-model="birthDay" :data="dayData"/>
                      </div>
                      <div class="col-md-4">
                        <TSelect setupText="Miesiąc" v-model="birthMonth" :data="monthData"/>
                      </div>
                      <div class="col-md-4">
                        <TSelect setupText="Rok" v-model="birthYear" :data="yearData"/>
                      </div>
                    </div>
                  </div>

                  <div class="form-field">
                    <label class="form-label"  for="">Płeć</label>
                    
                    <div class="row">
                      <div class="col-md-6">
                        <TSelect setupText="Płeć" v-model="gender" :data="[{text: 'Kobieta', value: 'K'},{text: 'Mężczyzna', value: 'M'}]"/>

                      </div>
                    </div>
                  
                  </div>
                  
                  
              
                
              </div>
            
              <div class="col-md-5">
            
                  <div class="form-field">
                    <label class="form-label"  for="">Placówka prowadząca</label>
                    <TSelect setupText="Placówka" v-model="placeListModel" :data="placeList"/>

                    

                    
                  </div>
                  <div class="form-field text-right" v-if="false">
                    <router-link :to="`/placowki`" class="btn btn--primary btn--only-border ">Dodaj placówkę</router-link>

                  </div>
                
                  <div class="form-field">
                    <label class="form-label"  for="">Lekarz prowadzący</label>
                    <input type="text"  v-model="mainDoctor"  />
                  </div>

             
              
              </div>
            
            </div>
            <div class="text-right">
              <button type="submit" class="btn btn--primary">Zapisz dane</button>
            </div>
          </form>


   
        </Box>
      </div>

      <div class="col-md-8">
        <Box title="Uprawnienia użytkownika">

          <div class="row">
            <div class="col-12">
              <form action="" @submit="onChangeUserRole">
                  <TRadios inline="true" v-model="userRole" :data="userRoleData"/>
              
                <div class="text-right">
                  <button type="submit" class="btn btn--primary">Zmień uprawnienia</button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </div>
     </div>

    </div>

</template>

<script>
import { format, addMinutes } from 'date-fns';
import Topbar from '@/components/Topbar.vue';
import FormFooter from '@/components/FormFooter.vue';
import TSelect from '@/components/form/TSelect.vue';
import TCheckbox from '@/components/form/TCheckbox.vue';
import TCheckboxes from '@/components/form/TCheckboxes.vue';
import TRadios from '@/components/form/TRadios.vue';
import Box from '@/components/Box.vue';
import {ref, inject, onMounted, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useFooterGap} from '@/hooks/useFooterGap';

export default {
  name: 'AdminEditUserAccount',
  components: {
    Topbar,TSelect,TCheckbox,Box,TCheckboxes,TRadios,FormFooter
  },
  props: {
    formFooter: Boolean,
  },
  emit: ['alert'],
   setup(props,{emit}) {
    useFooterGap(props.formFooter,emit);
    const axios = inject('axios');
    const router = useRouter();
    const route = useRoute();
    let changesInAccountModel = ref(1);
    let changesInAccountData = ref([{value: 1, text: 'Zmiana hasła'}, {value: 2, text: 'Usunięcie konta',}]);
    const monthData = ref(rangeSelectData(1,12));
    const dayData = ref(rangeSelectData(1,31));
    const yearData = ref(rangeSelectData(1920,new Date().getFullYear()));
   
    const birthDay = ref(1);
    const birthMonth = ref(1);
    const birthYear = ref(1990);

    const firstName = ref('');
    const lastName = ref('');
    const mainDoctor = ref('');

    const gender = ref('M');
    const desc = ref('');
    const title = ref('');
    const footerHeight = ref(100);
    const placeList = ref([]);
    const placeListModel = ref('');

    const currentPass = ref('');
    const currentPassDelete = ref('');
    const newPass = ref('');
    const newPassRepeat = ref('');
    const userEmail = ref('');
    const userRole = ref('user');
    const userRoleData = ref([{value: 'user', text: 'Pacjent'},{value: 'admin', text: 'Administrator'}]);
    onMounted( async() => {
        await axios.get(`/admin/users/${route.params.id}`)
        .then( async response => {
          userEmail.value= response.data.data.email;
          userRole.value = response.data.data.role;
          const data = response.data.data.patientProfile;
          if(data.medicalFacilities.length){
            placeList.value = data.medicalFacilities.map(drug =>{
              return {
                value: drug.id,
                text: drug.name,
              }
            }); 
            
              placeListModel.value = data.medicalFacilityId ? data.medicalFacilityId : NULL;
            
          
          }
         

           
            mainDoctor.value = data.doctor ? data.doctor : '';;
            firstName.value = data.firstName ? data.firstName : '';
            lastName.value = data.lastName ? data.lastName : '';
            birthDay.value = format(new Date(data.birthday), 'd');
            birthMonth.value = format(new Date(data.birthday), 'M');
            birthYear.value = format(new Date(data.birthday), 'y');
            if(data.gender == null){
              gender.value = 'M'
            }else{
              gender.value = data.gender;

            }
        });

    
   });

      

  
  async function onFormSubmit(evt){
    evt.preventDefault();
    let data = {
      birthday: format(new Date(birthYear.value,  birthMonth.value -1, birthDay.value), 'y-MM-dd'),
      firstName: firstName.value,
      lastName: lastName.value,
      doctor: mainDoctor.value,
      gender: gender.value,
      role:  userRole.value,
    }

    if(placeListModel.value){
      data.medicalFacilityId =placeListModel.value;
    }
 
    await axios.put(`/admin/users/${route.params.id}/patient-profile`,data)
      .then(() => {
        emit('alert',{
                type: 'success',
                title: `Profil ${userEmail.value} został zaaktualizowany!`,
                visible: true,
                autoClose: true,
                data: []
              } );
              router.push('/admin');
      })
      .catch(err => {
      
          emit('alert',{
                type: 'error',
                title: 'Wystąpił problem z aktualizacją profilu',
                data: [err.response.data.error.message],
                visible: true,
           } );
      });
   }
   async function onChangeUserRole(evt){
    evt.preventDefault();
    let data = {
      role:  userRole.value,
    }

 
    await axios.put(`/admin/users/${route.params.id}`,data)
      .then(() => {
        emit('alert',{
                type: 'success',
                title: `Uprawnienia ${userEmail.value} zostały zmienione!`,
                visible: true,
                autoClose: true,
                data: []
              } );
              router.push('/admin');
      })
      .catch(err => {
      
          emit('alert',{
                type: 'error',
                title: 'Wystąpił problem z ze zmianą uprawnień',
                data: [err.response.data.error.message],
                visible: true,
           } );
      });
      
    
   }
   function rangeSelectData(start, end) {
    let tempArr = [];
    let length = end - start;
    for(var i =0; i <= length; i++){
      tempArr.push({text: start + i,value: start + i})
    }

    return tempArr;
   }
  
    return {
      footerHeight,
      title,
      onFormSubmit,
      dayData,
      monthData,
      yearData,birthDay,
      birthMonth,
      birthYear,
      gender,
      mainDoctor,
      placeList,
      placeListModel,
      firstName,
      lastName,
      changesInAccountModel,
      changesInAccountData,
      currentPass,
      currentPassDelete,
      newPass,
      newPassRepeat,
      userRole,
      userRoleData,
      onChangeUserRole
    };
  },
}
</script>


<style lang="scss" scoped>
  .terms-block{
    display: flex;
    column-gap: 10px;
    .btn{
      margin: 10px 0;
    }
  }

</style>