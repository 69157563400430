<template>
 
        <div class="footer__bottom" :class="{'footer__bottom--gap' : needGap}">
            <small class="footer__credit">
				      <img src="https://hily.pl/wp-content/uploads/2023/03/SKYMED_sezon_2018_BP.png" class="img-responsive">
              <p>Copyright © Hily. Wszystkie prawa zastrzeżone. Pomysł i organizacja: <a href="http://skymed.pl">SKYMED.PL</a></p>
            </small>

            <a href="https://taureca.com" target="_blank" class="footer__author" title="Strony internetowe ">
              <span>Realizacja</span>
              <img src="https://taureca.com/taureca_strony_sklepy_internetowe_aplikacje_logo_dark.svg" class="footer__author-image">
            </a>
          </div>

</template>

<script>

export default {
  name: 'TermFooter',
  props: {
    needGap: Boolean,
  }


}
</script>


<style lang="scss" scoped>
 .footer{
    &__bottom{
      margin-top: auto  ;
        padding: 15px 80px 15px 30px;
        border-top: 1px solid rgba(255,255,255,.3);
        background: #f4faff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >*{
            margin-right: 40px;
            
            &:last-child{
                margin-right: 0;
            }
        }

        @media (max-width: $lg){
            flex-direction: column;
            text-align: center;
            >*{
                margin-right: 0;
                margin-bottom: 20px;

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }

        @media (max-width: $md){
          padding: 15px 20px 15px 20px;
        }

        &--gap{
          margin-bottom: 100px;

          @media(max-width: $xs){
            margin-bottom: 150px;
          }
        }
    }

    &__credit{
       font-size: 13px;
       font-weight: 500;
       display: flex;
       align-items: center;
       @media(max-width: $sm){
        flex-direction: column;;
       }
       img{
        max-width: 120px;
        height: auto;
        flex-shrink: 0;
        margin-right: 20px;

        @media(max-width: $sm){
          max-width: 160px;
          margin-right: 0;
          margin-bottom: 20px;
       }
       }
    }

    &__author{
        color: $text_color;
        text-decoration: none;
        display: flex;
        align-items: center;
        font-size: 14px;
        span{
            font-weight: 600;
        }
        &-image{
            margin-left: 20px;
            width: 80px;
            height: auto;
        }

    }

    
 }
</style>