<template>

  <div class="t-checkbox" :class="className"  v-show="typeof value !== 'undefined'">
      <input type="checkbox" v-model="model" :name="name" :id="id"  :value="value"   :class="{isLarge: typeof isLarge != 'undefined' ? isLarge : false}"  />
      <label :for="id">
        {{ text }}
      </label>
  </div>
</template>

<script>
import {watch,ref,onMounted } from 'vue';
import uniqid from 'uniqid';
export default {
  name: 'TCheckbox',
  props: {
    name: String,
    value: [String, Number],
    text: String,
    className: String,
    id: String,
    isLarge: Boolean,
    modelValue: Object,
  },
  emit: ['update:modelValue'],
  setup(props, {emit} ){
    let model = ref(props.modelValue);
    const id = uniqid();
    watch(model, newVal=>{
      emit('update:modelValue', newVal);
    })

    return {model,id};
  },

  // methods: {
  //   onChange(e){
  //     console.log(e.target.value);
  //   }
  // }
}
</script>


<style lang="scss" scoped>

.t-checkbox{
  margin-bottom: 10px;
  input{
   
    opacity: 0;
    display: none;
    ~label{
      position: relative;
      padding-left: 1.8em;
      cursor: pointer;
      font-size: 14px;
      display: inline-block;
      &::before{
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 1.2em;
        height: 1.2em;
        border: 1px solid $border_color;
        display: inline-block;
        
      }
    }

    &.isLarge{
      ~label{
        font-size: 16px;
        padding-left: 2.2em;
        // margin-bottom: 20px;
        &::before{
          width: 1.7em;
          height: 1.7em;
          top: 3px;
        }
      }
    }
    &:checked{
      ~label{
        &::before{
          background-image: url('@/assets/checkbox.svg');
          background-size: 90%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
 
  }
  
}
  
</style>