
<template>
    <Topbar title="Dane pacjenta"/>
 
    <div class="app__content__wrapper" >
      <div class="row">
     <div class="col-md-4">
      <div class="row">
   
        <div class="col-12">
          <Box title="Informacje o pacjencie">
            <ul class="login-data">
              <li class="login-data__item"><b>Imię: </b> {{ firstName }}</li>
              <li class="login-data__item"><b>Nazwisko: </b> {{ lastName }}</li>
              <li class="login-data__item"><b>Data urodzenia: </b> {{ `${birthDay}/${birthMonth}/${birthYear}` }}</li>
              <li class="login-data__item"><b>Płeć: </b> {{ gender == 'M' ? 'Mężczyzna' : 'Kobieta' }}</li>
            </ul>
            
          <div class="terms-block">
            <a href="https://hily.pl/regulamin-aplikacja" target="_blank" class="btn btn--primary btn--only-border">Regulamin</a>
            <a href="https://hily.pl/polityka-prywatnosci-aplikacja" target="_blank" class="btn btn--primary btn--only-border">Polityka prywatności</a>
          </div>
          </Box>
        </div>
    
      </div>
       
      </div>


     </div>

    </div>

</template>

<script>
import { format, addMinutes } from 'date-fns';
import Topbar from '@/components/Topbar.vue';
import FormFooter from '@/components/FormFooter.vue';
import TSelect from '@/components/form/TSelect.vue';
import TCheckbox from '@/components/form/TCheckbox.vue';
import TCheckboxes from '@/components/form/TCheckboxes.vue';
import TRadios from '@/components/form/TRadios.vue';
import Box from '@/components/Box.vue';
import {ref, inject, onMounted, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useFooterGap} from '@/hooks/useFooterGap';
export default {
  name: 'ViewersMyAccountView',
  components: {
    Topbar,TSelect,TCheckbox,Box,TCheckboxes,TRadios,FormFooter
  },
  props: {
    formFooter: Boolean,
  },
  emit: ['alert'],
   setup(props,{emit}) {
    useFooterGap(props.formFooter,emit);
    const axios = inject('axios');
    const router = useRouter();
    const route = useRoute();
    let changesInAccountModel = ref(1);
    let changesInAccountData = ref([{value: 1, text: 'Zmiana hasła'}, {value: 2, text: 'Usunięcie konta',}]);
    const monthData = ref(rangeSelectData(1,12));
    const dayData = ref(rangeSelectData(1,31));
    const yearData = ref(rangeSelectData(1920,new Date().getFullYear()));

    const birthDay = ref(1);
    const birthMonth = ref(1);
    const birthYear = ref(1990);

    const firstName = ref('');
    const lastName = ref('');
    const mainDoctor = ref('');

    const gender = ref('M');
    const desc = ref('');
    const title = ref('');
    const footerHeight = ref(100);
    const placeList = ref([]);
    const placeListModel = ref('');

    const currentPass = ref('');
    const currentPassDelete = ref('');
    const newPass = ref('');
    const newPassRepeat = ref('');

    onMounted( async() => {
        await axios.get(`/viewers/${route.params.uId}/patient-profile`)
        .then( async response => {
          const data = response.data.data;
          if(data.medicalFacilities.length){
            placeList.value = data.medicalFacilities.map(drug =>{
              return {
                value: drug.id,
                text: drug.name,
              }
            }); 
            
            placeListModel.value = data.medicalFacilityId ? data.medicalFacilityId : NULL;
          }
         

           
            mainDoctor.value = data.doctor ? data.doctor : '';;
            firstName.value = data.firstName ? data.firstName : '';
            lastName.value = data.lastName ? data.lastName : '';
            birthDay.value = format(new Date(data.birthday), 'd');
            birthMonth.value = format(new Date(data.birthday), 'M');
            birthYear.value = format(new Date(data.birthday), 'y');
            if(data.gender == null){
              gender.value = 'M'
            }else{
              gender.value = data.gender;

            }
        });

    
   });

      
   async function onEditPass(evt){
    evt.preventDefault();
    let errors = [];

    if(!newPass.value.length || !newPassRepeat.value.length || !currentPass.value.length){
      errors.push('Nie wszystkie pola są uzupełnione');
    }else{
      if(newPass.value !== newPassRepeat.value){
        errors.push('Hasła się nie zgadzają');
      }

      if(currentPass.value.length < 6){
        errors.push('Obecne hasło musi mieć więcej jak 5 znaków');
      }
    }
  
    if(errors.length){
        emit('alert',{
                type: 'error',
                title: 'Wystąpił problem ze zmianą hasła',
                data: errors,
                visible: true,
           } );

    }else{
      await axios.post(`/user-account/change-password`,{
        password: currentPass.value,
        newPassword: newPass.value,
      })
      .then(() => {
        emit('alert',{
                type: 'success',
                title: 'Twoje hasło zostało zmienione!',
                visible: true,
                autoClose: true,
                data: ['Może zalogować się nowym hasłem']
              } );
            localStorage.removeItem('token');
            localStorage.removeItem('isAdmin');
            router.push('/logowanie')
      
      })
      .catch(err => {
      
          emit('alert',{
                type: 'error',
                title: 'Wystąpił problem ze zmianą hasła',
                data: [err.response.data.error.message],
                visible: true,
           } );
      });
    }
 

      
    
   }
  
   async function onDeleteAccount(evt){
    evt.preventDefault();
    let errors = [];

    if(!currentPassDelete.value.length){
      errors.push('Pole z hasłem jest puste');
    }
    if(errors.length){
        emit('alert',{
                type: 'error',
                title: 'Wystąpił problem ze zmianą hasła',
                data: errors,
                visible: true,
           } );

    }else{
      if(confirm('Czy na pewno chcesz usunąć swoje konto w Hily?')){
        await axios.delete(`/user-account`,{
           data: {    password: currentPassDelete.value, }
       
        })
        .then(() => {
          emit('alert',{
                  type: 'success',
                  title: 'Dziękujemy za korzystanie z Hily!',
                  visible: true,
                  autoClose: true,
                  data: ['Twoje konto zostało usunięte']
                } );
              localStorage.removeItem('token');
              localStorage.removeItem('isAdmin');
              router.push('/logowanie')
        
        })
        .catch(err => {
        
            emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z usunięciem konta',
                  data: [err.response.data.error.message],
                  visible: true,
            } );
        });
      }

    }
 

      
    
   }
  async function onFormSubmit(evt){
    evt.preventDefault();
    let data = {
      birthday: format(new Date(birthYear.value,  birthMonth.value -1, birthDay.value), 'y-MM-dd'),
      firstName: firstName.value,
      lastName: lastName.value,
      doctor: mainDoctor.value,
      gender: gender.value,
   

    }

    if(placeListModel.value){
      data.medicalFacilityId =placeListModel.value;
    }
 
    await axios.put(`/patient-profile`,data)
      .then(() => {
        emit('alert',{
                type: 'success',
                title: 'Profil został zaaktualizowany!',
                visible: true,
                autoClose: true,
                data: []
              } );
      
      })
      .catch(err => {
      
          emit('alert',{
                type: 'error',
                title: 'Wystąpił problem z aktualizacją profilu',
                data: [err.response.data.error.message],
                visible: true,
           } );
      });
      
    
   }
  
   function rangeSelectData(start, end) {
    let tempArr = [];
    let length = end - start;
    for(var i =0; i <= length; i++){
      tempArr.push({text: start + i,value: start + i})
    }

    return tempArr;
   }
  
    return {
      footerHeight,
      title,
      onFormSubmit,
      dayData,
      monthData,
      yearData,birthDay,
      birthMonth,
      birthYear,
      gender,
      mainDoctor,
      placeList,
      placeListModel,
      firstName,
      lastName,
      changesInAccountModel,
      changesInAccountData,
      currentPass,
      currentPassDelete,
      newPass,
      newPassRepeat,
      onEditPass,
      onDeleteAccount,
    };
  },
}
</script>


<style lang="scss" scoped>
  .terms-block{
    display: flex;
    column-gap: 10px;
    .btn{
      margin: 10px 0;
    }
  }

.login-data{
  padding: 0;
  margin: 10px 0;
  
  &__item{
    list-style: none ;
    margin-bottom: 10px;
  }
}
</style>