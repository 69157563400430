<template>

  <div class="t-select" v-show="typeof data !== 'undefined'">
    <select :name="name"  v-model="value"  :class="[className]"  >
      <option value="" disabled >{{ setupText }}</option>
      <option  :key="index" v-for="(item,index) in data" :value="item.value">{{item.text}}</option>
    </select>
    <span class="t-select__arrow"><span>></span></span>
  </div>
</template>

<script>
import { propsToAttrMap } from '@vue/shared';
import {watch,ref,onMounted } from 'vue';
export default {
  name: 'TSelect',
  props: {
    data: Array,
    name: String,
    setupText: String,
    id: String,
    className: String,
    modelValue: [String, Number]
  },
  emit: ['update:modelValue'],
  setup(props, {emit} ){
    let value = ref(props.modelValue);
  
    // onMounted(() => {
    //   document.addEventListener('mouseup',()=>{
    //     console.log('alerto klikejkto');
    //   });
    // })
    watch(value, newVal => {
 
      emit('update:modelValue', newVal);
    });

    watch(()=> props.modelValue, newVal => {

        value.value =  newVal;
    });
    // watch(()=> props.selected, newVal => {
    //   value.value = newVal;
    // });

    return {value};
  },

  // methods: {
  //   onChange(e){
  //     console.log(e.target.value);
  //   }
  // }
}
</script>


<style lang="scss" scoped>

.t-select{
  position: relative;
  select {
    appearance: none;
    width: 100%;
    padding-right: 40px;
  }

  &__arrow{
   
    display: inline-flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    padding: 5px 10px;
    box-sizing: border-box;
    height: 50%;
    align-items: center;
    pointer-events: none;
    border-left: 1px solid $border_color;

    span{
      transform: rotate(90deg);
    }

  }
}
  
</style>