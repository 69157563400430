<template>
  <div class="chart-item">
    <header class="chart-item__header">
      <span :style="{backgroundColor: color}" class="chart-item__legend">
      </span>
      <span class="chart-item__name">{{ title }}
        <span class="chart-item__date-range">
      {{date}}
    </span>
      </span>
    </header>
   
  </div>

</template>

<script>
export default {
  name: 'ChartStat',
  props: {
    title: String,
    color: String,
    date: String,
  }
}
</script>


<style lang="scss" scoped>
.chart-item{
  &__header{
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
  }
  &__legend{
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 10px;

    transform: translateY(5px);
    flex-shrink: 0;
    @media(max-width: $md){
      width: 10px;
      height: 10px;
    }
  }

  &__name{
    font-size: 16px;
    font-weight: 700;

    @media(max-width: $md){
      font-size: 13px;
    }
    
  }

  &__date-range{
    font-size: 12px;
    display: block;
    font-weight: 500;
  }
}
</style>