<template>
 <div class="box" :class="class">
    <h3 class="box__title">{{ title }}</h3>
    <div class="box__content">
      <slot></slot>
    </div>
 </div>
</template>

<script>

export default {
  name: 'Box',
  props: {
    title: String,
    class: String,
  },

  setup(){
    
    return {};
  }
}
</script>


<style lang="scss" scoped>
  .box{
    padding: 35px 30px;
    border-radius: $border_radius;
    border: 1px solid $border_color;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
    margin-bottom: 30px;
    &__title{
      color: $text_color;
      font-size: 24px;
      font-weight: 700;
      margin-top: 0px;
      margin-bottom: 20px;;

      @media(max-width: $md){
        font-size: 17px;
        margin-bottom: 10px;
      }
    }

    @media (max-width: $md){
      padding: 25px 15px;
    }
  }
</style>