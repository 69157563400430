
<template>
  <Topbar title="Administracja"/>

  <div class="app__content__wrapper">
    <div class="row">
      <div class="col-xl-5">


        <Box title="Lista leków">
          <table class="base-table" :class="{'base-table--mobile': viewport}" v-show="drugsList.length">

            <thead>
              <th class="name" data-name="Nazwa leku">Nazwa leku</th>
              <th class="name" v-if="false">Typ</th>
              <th class="action">Akcja</th>
            </thead>

            <tbody>
              <tr v-for="(drag, index) in drugsList" :key="index">
                <td class="name" data-name="Nazwa leku">
                  {{ drag.name }}
                </td>
                <td class="name" v-if="false">{{ drag.types.toString() }}</td>
                <td class="action">
                  <div class="action__wrapper">
                    <router-link :to="`/admin/leki/edytuj/${drag.id}`" class="btn btn--primary btn--circle edit-drug"><img :src="editIcon"></router-link>
                    <button @click="onRemoveDrug(drag.id)" class="btn btn--secondary btn--circle remove-drug">X</button>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <router-link to="/admin/leki/dodaj" class="btn btn--primary btn--large">Dodaj lek</router-link>
          </div>
          
        </Box>
       
      </div>
      <div class="col-xl-7">
        <Box title="Użytkownicy">
          <table class="base-table" :class="{'base-table--mobile': viewport}" v-show="userList.length">

            <thead>
              <th class="email">Email</th>
              <th class="role" >Rola</th>
              <th class="action" >Akcja</th>
            </thead>

            <tbody>
              <tr v-for="(user, index) in userList" :key="index">
                <td class="email" data-name="Email">
                  
                  <span v-if="currentUser == user.id">
                    <b>{{ user.email }} (Ty)</b>
                  </span>
                  <span v-if="currentUser !== user.id">
                    {{ user.email }}
                  </span>
                </td>
                
                <td class="role" data-name="Rola">
                  {{ user.role }}
                </td>
                <td class="action" >
                  <div class="action__wrapper" v-if="currentUser !== user.id">
                    <router-link :to="`/admin/edytuj-profil/${user.id}`" class="btn btn--primary btn--circle edit-drug"><img :src="editIcon"></router-link>
                    <button @click="onRemoveUser(user.id,user.email)" class="btn btn--secondary btn--circle remove-drug">X</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          
        </Box>

</div>
    </div>
  </div>

</template>

<script>
import { format, addMinutes,} from 'date-fns';
import Topbar from '@/components/Topbar.vue';
import FormFooter from '@/components/FormFooter.vue';
import TSelect from '@/components/form/TSelect.vue';
import TCheckbox from '@/components/form/TCheckbox.vue';
import TCheckboxes from '@/components/form/TCheckboxes.vue';
import TRadios from '@/components/form/TRadios.vue';
import Box from '@/components/Box.vue';
import {ref, inject, onMounted, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import  edit_icon from '@/assets/edit_icon.svg' ;
import {useViewportChecker} from '@/hooks/useViewportChecker.js'
export default {
name: 'AdminHomeView',
components: {
  Topbar,TSelect,TCheckbox,Box,TCheckboxes,TRadios,FormFooter
},
emit: ['alert'],
 setup(props,{emit}) {
  const axios = inject('axios');
  const router = useRouter();
  let drugsList = ref([]);
  const editIcon = ref(edit_icon);
  let viewport = ref(useViewportChecker(992));
  let userList = ref([]);
  let currentUser = ref(0);
     onMounted( async() => {
      await   getData() 

    });

    async function getData() {
      await axios.get('/auth/me')
      .then(response => {
        currentUser.value = response.data.data.id;
      
      });
      await axios.get('/medications')
      .then(response => {

        drugsList.value = response.data.data.map(drug =>{
          return {
            id: drug.id,
            name: drug.name,
            types: drug.dosageForms.map(item => item.name)
          }
        }); 
      });

      await axios.get('/admin/users')
      .then(response => {

        userList.value = response.data.data.map(user =>{
          return {
            id: user.id,
            email: user.email,
            role: user.role,
          }
        }); 
    
      });
    }
    async function onRemoveDrug(id){
      await axios.delete(`/admin/medications/${id}`)
      .then( async response => {
        await   getData() ;

        emit('alert',{
              type: 'success',
              title: 'Lek został usunięty z systemu',
              visible: true,
              autoClose: true,
              data: []
            } );
        
      });
    }

    async function onRemovePlace(id){
      await axios.delete(`/patient-medical-facilites/${id}`)
      .then( async response => {
        await   getData() ;

        emit('alert',{
              type: 'success',
              title: 'Placówka została usunięta z systemu',
              visible: true,
              autoClose: true,
              data: []
            } );
        
      });
    }

    async function onRemoveUser(id, name){
      if(confirm(`Czy na pewno chcesz usunąć użytkownika ${name}?`)){
        await axios.delete(`/admin/users/${id}`)
          .then( async response => {
            await   getData() ;

            emit('alert',{
                  type: 'success',
                  title: `Użytkownik ${name} został usunięty z Hily`,
                  visible: true,
                  autoClose: true,
                  data: []
                } );
            
          });
      }
  
    }
  return {drugsList,onRemoveDrug,userList,onRemovePlace,editIcon,onRemoveUser,viewport, currentUser};
},
}
</script>


<style lang="scss" scoped>


</style>