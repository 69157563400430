
import { onMounted,ref} from "vue";

export function useViewportChecker (viewport){
    let mobileViewport = ref(window.innerWidth < viewport);
    onMounted(() => {
        window.addEventListener('resize', () => {
            mobileViewport.value = window.innerWidth < viewport;
          })
    })
   
    return mobileViewport;
}