<template>
  <div class="sidebar">
    <div class="sidebar__wrapper">
      <img class="img-responsive sidebar__logo" src="@/assets/hily_logo.svg" alt="App Logo">
      <Menu />
    </div>
    
  </div>
  
</template>

<script>
import '@/scss/main.scss';
import Menu from '@/components/Menu.vue'
export default {
  name: 'Sidebar',
  props: {
    msg: String,
    isViewer: Boolean,
    isAdmin: Boolean
  },
  components:{
    Menu
  }
}
</script>


<style lang="scss" >

  .sidebar{
    
    width: 220px;
    border-right: 1px solid #e8e8e8;

  
   
    &__logo{
      height: 169px;
      padding: 60px 30px 40px 30px;
    }

    &__wrapper{
      position: sticky;
      top: 0;
    }
    @media(max-width: 768px){
      width: 70px;
     
      &__logo{
        height: 100px;
        padding: 30px 15px 20px 15px;
      }
    }

   @media(max-width: $xs){
    position: fixed;
      z-index: 999999;
      background: white;
      height: calc(100% - 50px);
      overflow-y: auto;
   }
 
  }
</style>