<template>
   <div class="app__content__topbar">
    <div class="app__content__topbar__wrapper">
      <h1 class="app__content__topbar__title" v-if="title">{{ title }}</h1>
        <slot></slot>
    </div>
   
      <Today/>
  </div>
</template>

<script>
import Today from '@/components/Today.vue';
export default {
  name: 'Topbar',
  props: {
    title: String
  },
  components:{
    Today
  }



}
</script>


<style lang="scss" scoped>
.app__content__topbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 20px;
  padding-bottom: 20px;

  // @media(max-width: $md){
  //   flex-direction: column;
  //   align-items: start;
  //   justify-content: flex-start;
  // }

  &__wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 20px;

    @media(max-width: $md){
      margin-right: 10px;
    }
  }
  .today{
    margin-left: auto;
  }
  

  &__title{
    font-size: 32px;
    color: #031b61;
    font-weight: 700;
    margin-right: 30px;
  }

  @media(max-width: 1024px){
 
    padding-left: 50px;
    padding-right: 50px;

    &__title{
      font-size: 26px;
    }
  
  }

  @media (max-width: 768px){
    padding-left: 20px;
    padding-right: 20px;
    &__title{
      margin-right: 10px;
      font-size: 18px;
    }
   
  }

  @media (max-width: $sm){
 
    &__title{
   
      font-size: 16px;
    }
   
  }
}
</style>