<template>

        <div class="calendar__day">
            <header class="calendar__day__header">
              <span class="calendar__day__name">{{ name }}</span>
              <date class="calendar__day__date">{{ date }}</date>
            </header>
            <div class="calendar__day__events">
              <slot></slot>
            </div>
          </div>
</template>

<script>

export default {
  name: 'CalendarDay',
  props: {
    name: String,
    date: String
  },

  setup(){
    
    return {};
  }
}
</script>


<style lang="scss" scoped>

</style>