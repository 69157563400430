
<template>
    <Topbar title="Edycja wizyty"/>
 
    <div class="app__content__wrapper" >

      <Box title="Aktualizuj wizytę">
        <form action="" @submit="onFormSubmit" >
          <div class="row">
            <div class="col-lg-6">
              <div class="form-field">
                <label class="form-label"  for="">Tytuł</label>
                <input  v-model="title"  cols="30" rows="10"/>
              </div>
            

               <div class="row">
                <div class="col-xl-6">
                  <VDatePicker ref="calendarPicker" :rules="rules" mode="dateTime" is24hr hide-time-header   trim-weeks  :isRange="false" title-position="left" borderless  v-model="date" :max-date="new Date()"/>
             
                </div>
                <div class="col-xl-6">
                  <label class="form-label">Czas trwania wizyty</label> 
                  <div class="form-field ">
                    <label class="form-label"  for="">Godziny</label>
                    <TSelect v-model="hour" setupText="Godziny" :data="[{value: 0, text: '0'},{value: 1, text: '1'},{value: 2, text: '2'},{value: 3, text: '3'},{value: 4, text: '4'}]"></TSelect>
                  </div>
                  <div class="form-field form-field">
                    <label class="form-label"  for="">Minuty</label>
                    <TSelect v-model="minutes" setupText="Minuty" :data="[{value: 0, text: '0'},{value: 15, text: '15'},{value: 30, text: '30'},{value: 45, text: '45'},]"></TSelect>
                  </div>
                </div>
               </div>
             

            

            </div>
            <div  class="col-lg-6">
              <div class="form-field">
                <label class="form-label"  for="">Doktor</label>
                <input  v-model="doctor"  cols="30" rows="10"/>
              </div>
              <div class="form-field">
                <label class="form-label"  for="">Adres placówki</label>
                <input  v-model="locationAddress"  cols="30" rows="10"/>
              </div>
              <div class="form-field">
                <label class="form-label"  for="">Opis </label>
                <textarea  v-model="desc"  cols="30" rows="10"></textarea>
              </div>


            </div>
          </div>
         
     
          <FormFooter :height="footerHeight">
            <button type="submit" class="btn btn--primary btn--large">Zapisz</button>
          </FormFooter>
          </form>

      </Box>
     

    </div>

</template>

<script>
import { format, addMinutes,differenceInMinutes,minutesToHours } from 'date-fns';
import Topbar from '@/components/Topbar.vue';
import FormFooter from '@/components/FormFooter.vue';
import TSelect from '@/components/form/TSelect.vue';
import TCheckbox from '@/components/form/TCheckbox.vue';
import TCheckboxes from '@/components/form/TCheckboxes.vue';
import TRadios from '@/components/form/TRadios.vue';
import Box from '@/components/Box.vue';
import {ref, inject, onMounted, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useFooterGap} from '@/hooks/useFooterGap';
export default {
  name: 'AppointmentsView',
  components: {
    Topbar,TSelect,TCheckbox,Box,TCheckboxes,TRadios,FormFooter
  },
  props: {
    formFooter: Boolean,
  },
  emit: ['alert'],
   setup(props,{emit}) {
    useFooterGap(props.formFooter,emit);
    const axios = inject('axios');
    const router = useRouter();
    const route = useRoute();
    let date = ref(new Date());
    const rules = ref({
      minutes: [0,5,10,15,20,25,30,35,40,45,50,55],
  
    });
    const footerHeight = ref(100);
    let testsFields = ref({})
    let place = ref('');
    let desc = ref('');
    let hour = ref('');
    let minutes = ref(30);
    let locationAddress = ref('');
    let doctor = ref('');
    let title = ref('');
    let testType = ref([]);
    let testData = ref([]);
    let testTypeSelected = ref(0);

    let selectedData = ref([]);
       onMounted( async() => {
        await axios.get(`/appointments/${route.params.id}`)
        .then(response => {
        
          let item = response.data.data;
            desc.value = item.description;
            locationAddress.value = item.place;
            title.value = item.title;

            date.value = new Date(item.startDate);

            let tempMinutes = differenceInMinutes(new Date(item.endDate),new Date(item.startDate));
            let tempHours = minutesToHours(tempMinutes);
            tempMinutes =  tempMinutes > 60 ?   tempMinutes - (tempHours*60) : tempMinutes;


            minutes.value = tempMinutes;
            hour.value = tempHours;
        }).catch(() =>{
          router.push('/');
        });;;
      });
    const placeData = [{text: 'Szpital', checked: true}, {text: 'Dom'}, {text: 'Poradnia'}];
 
    watch(testTypeSelected, newVal =>{
      selectedData.value = testData.value.filter(item=> newVal == item.id)[0]
    });
    watch(selectedData, newVal =>{
      testsFields.value= {};

      newVal.data.forEach(item => {
        testsFields.value[item.uId] = ref('');
      });
     
    });
   
    function onChangeTestSelect(event) {
      testTypeSelected.value = event;
    };

    function  onChangePlace(event) {
    
        place.value = event;
    };

   async function onFormSubmit(evt){
    evt.preventDefault();
  
    var errorData = [];

    if(!title.value.length){
      errorData.push('Pole z tytułem jest puste');
    }

    if(minutes.value == 0 && hour.value == 0){
      errorData.push('Czas trwania jest równy 0');

    }
    if(!date.value){
      errorData.push('Nie wybrano daty');
    }

    if(errorData.length){
      emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z zapisem badania',
                  data: errorData,
                  visible: true,
              } );
    }else{
        let data = {
        "title": title.value,
        "description": desc.value,
        "place": locationAddress.value,
        "startDate": new Date(date.value),
        "endDate": new Date(addMinutes(date.value, hour.value * 60 + minutes.value ))
      }


  
   

    await axios.put(`/appointments/${route.params.id}`,data)
      .then(response => {
        emit('alert',{
                type: 'success',
                title: 'Wizyta została zaaktualizowana!',
                visible: true,
                autoClose: true,
                data: []
              } );
        router.push({ path: '/kalendarz', query: { date: format(date.value, 'yyyy-MM-dd')}} );

      
      })
      .catch(err => {
        emit('alert',{
                type: 'error',
                title: 'Wystąpił problem z aktualizacją wizyty',
                data: [err.response.data.error.message],
                visible: true,
           } );

      
      });
    } 
   }
    return { doctor,title, footerHeight,  locationAddress,hour,minutes,desc,onFormSubmit,testsFields,place,placeData,date,rules,testType,testData,testTypeSelected,selectedData,onChangeTestSelect,onChangePlace};
  },
}
</script>


<style lang="scss" scoped>
  

</style>