<template>
 <div  class="sidebox-content sidebox-content--type-visit" >
    <header class="sidebox-content__header">
      <h3 class="sidebox-content__title">
        Wizyta, <br>
        <b>{{ data.desc }}</b>
      </h3>
    

      <div class="sidebox-content__header__buttons" v-if="!visitMode">
        <router-link  class="btn btn--secondary" :to="`/wizyty/edytuj/${data.id}`">Edytuj</router-link>
        <button class="btn btn--primary" @click="deleteItem(data.id)" >Usuń</button>
      </div>
    </header>
    
    <div class="sidebox-content__main-info">
      <ul class="info-icon-list">
        <li class="info-icon-list__item">
          <div class="info-icon-list__item__icon-wrapper">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="50px" height="54.5px" viewBox="150 45.5 500 504.5" enable-background="new 150 45.5 500 504.5" xml:space="preserve">
              <g id="icons">
                <g id="calendar">
                  <path fill="#9CDABE" d="M550,100c0-27.5-22.5-50-50-50s-50,22.5-50,50H350c0-27.5-22.5-50-50-50s-50,22.5-50,50
                    c-55,0-100,45-100,100v250c0,55,45,100,100,100h300c55,0,100-45,100-100V200C650,145,605,100,550,100z M600,475c0,15-10,25-25,25
                    H225c-15,0-25-10-25-25V275c0-15,10-25,25-25h350c15,0,25,10,25,25V475z"/>
                  <path fill="#9CDABE" d="M425,300h-50c-27.5,0-50,22.5-50,50v50c0,27.5,22.5,50,50,50h50c27.5,0,50-22.5,50-50v-50
                    C475,322.5,452.5,300,425,300z"/>
                </g>
              </g>
              </svg>

          </div>

          {{ data.date }}
        </li>

        <li class="info-icon-list__item">
          <div class="info-icon-list__item__icon-wrapper">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="50px" height="50px" viewBox="150 50 500 500" enable-background="new 150 50 500 500" xml:space="preserve">
            <path fill="#9CDABE" d="M400,50c-138.071,0-250,111.929-250,250c0,138.07,111.929,250,250,250s250-111.93,250-250
              C650,161.929,538.071,50,400,50z M400,500c-110.457,0-200-89.544-200-200c0-110.457,89.543-200,200-200s200,89.543,200,200
              C600,410.456,510.457,500,400,500z M425,150h-50v175h150v-50H425V150z"/>
            </svg>


          </div>

          {{ data.timeStart }} - {{ data.timeEnd }}
        </li>

        <li class="info-icon-list__item">
          <div class="info-icon-list__item__icon-wrapper" v-show="false">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="41px" height="42.95px" viewBox="195.5 95.5 410 429.5" enable-background="new 195.5 95.5 410 429.5"
              xml:space="preserve">
            <circle fill="#9CDABE" cx="400" cy="200" r="100"/>
            <path fill="#9CDABE" d="M600,475v25c0,13.807-11.192,25-25,25H225c-13.807,0-25-11.193-25-25v-25c0-82.843,67.157-150,150-150h100
              C532.843,325,600,392.157,600,475z"/>
            </svg>


          </div>

          {{ data.doctorName }} 
        </li>
      </ul>
    </div>

    <div class="sidebox-content__additional-info" v-show="data.location.length">
      <h3 class="sidebox-content__additional-info__title">Dane gabinetu</h3>
      <ul class="info-icon-list">
        <li class="info-icon-list__item" >
          <div class="info-icon-list__item__icon-wrapper">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="45px" height="60.125px" viewBox="175 0 450 601.25" enable-background="new 175 0 450 601.25" xml:space="preserve">
              <g id="icons">
                <path id="pin" fill="#9CDABE" d="M400,0C275,0,175,100,175,225c0,155,85,292.5,212.5,372.499c7.5,5.001,17.5,5.001,27.5,0
                  C540,515,625,380,625,225C625,100,525,0,400,0z M400,325c-55,0-100-45-100-100s45-100,100-100s100,45,100,100S455,325,400,325z"/>
              </g>
            </svg>


          </div>

          <span v-html="data.location"></span>
       
        </li>

        <li class="info-icon-list__item" v-show="false">
          <div class="info-icon-list__item__icon-wrapper">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="50px" height="50px" viewBox="175 56.954 468.047 468.046" enable-background="new 175 56.954 468.047 468.046"
              xml:space="preserve">
            <path fill="#9CDABE" d="M620.312,371.249L510.39,324.375c-11.53-5.169-24.912-4.017-35.391,3.047l-58.828,39.14
              c-35.854-17.413-64.882-46.277-82.5-82.03l0,0L372.578,225c7.012-10.482,8.246-23.799,3.281-35.391L328.75,79.688
              c-6.746-15.231-22.583-24.336-39.141-22.5C224.146,65.763,175.145,121.478,175,187.5C175,373.593,326.406,525,512.5,525
              c66.022-0.146,121.737-49.146,130.313-114.609C644.648,393.833,635.545,377.995,620.312,371.249z"/>
            </svg>



          </div>

          {{ data.locationPhone }}
        </li>

     
      </ul>
    </div>
    <div class="sidebox-content__additional-info sidebox-content__additional-info--desc" v-if="data.description">
        <h3 class="sidebox-content__additional-info__title">Opis</h3>

        <p class="sidebox-content__desc">
          {{ data.description }}
        </p>
      </div>
 </div>
</template>

<script>
import {ref,watch} from 'vue';
export default {
  name: 'SideboxContentTypeVisit',
  props: {
    data: Object,
    visitMode:{
      type: Boolean,
      default: false
    },
  },

  setup(props,{emit}){

    function deleteItem(id){
      emit('deleteEvent',id);
    }
    return {deleteItem};
    }
  }
</script>


<style lang="scss" scoped>

</style>