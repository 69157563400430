
<template>
    <Topbar title="Eksport danych"/>
 
    <div class="app__content__wrapper" >
      <div class="row align-items-stretch">
        <div class="col-xl-6">
          <Box title="Eksport PDF" >
            <form action="" @submit="onFormSubmit" >
                <div class="row">
                  <div class="col-12">
                    <p>Eksport danych to informacje w formacie PDF o twoich badaniach i przyjętych lekach. Dane zostaną przedstawione zarówno w tabeli jak i na wykresie</p>
                      <VDatePicker   ref="calendarPicker" :max-date="maxRanges.max"   mode="date" is24hr hide-time-header   trim-weeks  :isRange="true" title-position="left" borderless  v-model="range" />
                  </div>
                  <div class="col-12">
                    <div class="text-right">
                      <button type="submit" class="btn btn--primary btn--large">Eksportuj</button>

                    </div>
                  </div>
                </div>
            </form>
          </Box>
        </div>

        <div class="col-xl-6">
          <Box title="Link do aplikacji" >
            <form action="" @submit="onAddToken" >
                <div class="row">
                  <div class="col-12">
                    <p>Możesz udostępnić swoje dane po przez unikalny link do Hily.  <br>
                      Poniżej wygeneruje Ci się unikalny link który możesz wysłać na przykład swojemu Lekarzowi.
                    </p>
                   
                  </div>
                  <div class="col-12" v-if="!linksData.length">
                    <div class="form-field">
                      <label class="form-label">Określ dostęp do Twoich danych</label>
                      <TRadios 
                        name="linkDateRange" 
                        class="chart-range__buttons" 
                        inline="true" 
                        v-model="linkDateRangeModel" 
                        :data="[{value: '3', text: '3 dni'} ,{value: '7', text: 'Tydzień'} ,{ value: 14, text: '2 tygodnie'},{ value: 30, text: '30 Dni'} ,{ value: 0, text: 'Własna data'}]"
                        />
                      <DateToggle v-if="linkDateRangeModel == 0" :minDate="new Date()" :isRange="false" buttonText="Zmień datę" v-model="linkRange"/>
                    </div>
                  </div>

                  <div class="col-12">
                    <table class="base-table" :class="{'base-table--mobile': viewport}" v-show="linksData.length">
                      <thead>
                        <th class="date">Data wygaśnięcia</th>
                        <th class="link" >Link</th>
                        <th class="action" >Akcja</th>
                      </thead>

                      <tbody>
                        <tr v-for="(link, index) in linksData" :key="index">
                          <td class="date" data-name="Data wygaśnięcia">
                            {{ link.expiredAt }}
                          </td>
                          <td class="link" data-name="Link">
                            <b v-if="link.expired">Wygasł</b>
                            <button v-if="!link.expired" @click.prevent="copyToClipboard(`https://app.hily.pl/podglad/${link.uId}`)" class="btn btn--primary btn--only-border">skopiuj</button>
                          </td>
                        
                          <td class="action" >
                            <div class="action__wrapper" >
                              <button @click.prevent="onRemoveLink(link.uId)" class="btn btn--secondary btn--circle remove-drug">X</button>
                            </div>
                          </td>
                        </tr>
                      </tbody>

                    </table>

                  </div>
             
                  <div class="col-12" v-if="!linksData.length">
                      <div class="text-right">
                        <button type="submit" class="btn btn--primary btn--large">Stwórz link</button>

                      </div>
                  </div>
                </div>
            </form>
          </Box>
        </div>

      </div>
    </div>

</template>

<script>
import { format,setDate, subDays,addDays,differenceInCalendarDays,set,add,isEqual,parseISO,subYears,  } from 'date-fns';

import Topbar from '@/components/Topbar.vue';
import FormFooter from '@/components/FormFooter.vue';
import TSelect from '@/components/form/TSelect.vue';
import TCheckbox from '@/components/form/TCheckbox.vue';
import TCheckboxes from '@/components/form/TCheckboxes.vue';
import TRadios from '@/components/form/TRadios.vue';
import Box from '@/components/Box.vue';

import DateToggle from '@/components/DateToggle.vue';
import {ref, inject, onMounted, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useFooterGap} from '@/hooks/useFooterGap';

export default {
  name: 'ExportView',
  components: {
    Topbar,TSelect,TCheckbox,Box,TCheckboxes,TRadios,FormFooter,DateToggle
  },
  props: {
    formFooter: Boolean,
  },
  emit: ['alert', 'removeAlerts'],
   setup(props,{emit}) {
    useFooterGap(props.formFooter,emit);
    const axios = inject('axios');
    const router = useRouter();
    const linkDateRangeModel = ref(3);
    const footerHeight = ref(100);
    const linksData = ref([]);
    let linkRange = ref(addDays(new Date(), 2));
    const range = ref({
          start:  new Date().setDate(new Date().getDate() - 7),
          end:  new Date(),
      });

      const maxRanges = ref({
       
        max: new Date()
      })

      onMounted(async ()=>{
        await getData();
      })
      async function getData(){
        axios.get('/viewers')
        .then(response=>{
          linksData.value = response.data.data.map(item =>{
            return {
              id: item.id, 
              uId: item.uId, 
              expiredAt: item.expiredAt,
              expired: item.expired
            };
          })
          
        })
      }
      watch(linkDateRangeModel, newVal => {
          if(newVal != 0){
            linkRange.value = addDays(new Date() , newVal) ;

          }
      })
     async function onAddToken(evt){
        evt.preventDefault();

        
      await axios.post('/viewers/',{
          expiredAt: format(linkRange.value, 'Y-MM-dd')

          
        })
        .then(async response => { 
          await getData();
            emit('alert',{
                  type: 'success',
                  title: 'Link został dodany',
                  data: [],
                  visible: true,
                  autoClose: true,
              } );

        })
        .catch(err => {
  
          emit('alert',{
                type: 'error',
                title: 'Wystąpił problem z dodaniem linku',
                data: [err.response.data.error.message],
                visible: true,
          } );

        });;
    }
   async function onFormSubmit(evt){
    evt.preventDefault();

    var errorData = [];

    if(!range.value){
      errorData.push('Nie wybrano daty');
    }

    if(errorData.length){
      emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z zapisem badania',
                  data: errorData,
                  visible: true,
              } );
    }else{
      emit('removeAlerts');


      await axios.get('/patient-profile/report',{
          params:{start: format(range.value.start, 'yyyy-MM-dd'), end:format(range.value.end, 'yyyy-MM-dd')},
      
            responseType: 'blob' //Force to receive data in a Blob Format
          
        })
        .then(response => {
             
         
          if(typeof response.data.message != 'undefined'){
            emit('alert',{
                  type: 'error',
                  title: response.data.message,
                  visible: true,
             
                  data: []
                } );
          }else{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Hily_raport.pdf');
            document.body.appendChild(link);
            link.click();
          }
      
        
        })
        .catch(err => {
          if(err.response.status == 404){
            emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z eksportem danych',
                  data: ['Brak wyników badań w tym zakresie'],
                  visible: true,
            } );
          }
   
        });
      }
  
   
   }

  async function onRemoveLink(id){
    await axios.delete(`/viewers/${id}`,)
        .then( async response => { 
            await getData();
            emit('alert',{
                  type: 'success',
                  title: 'Link został usunięty',
                  data: [],
                  visible: true,
                  autoClose: true,
              } );
        })
        .catch(err => {
  
            emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z usunięciem linku',
                  data: [err.response.data.error.message],
                  visible: true,
            } );
     
        });
   
   }
   function copyToClipboard(text){
    navigator.clipboard.writeText(text);
      emit('alert',{
                  type: 'success',
                  title: 'Link został skopiowany',
                  data: [],
                  visible: true,
                  autoClose: true,
              } );
   }
    return {  footerHeight, range, onFormSubmit,maxRanges,linksData,onAddToken,copyToClipboard,onRemoveLink,linkRange,linkDateRangeModel};
  },
}
</script>


<style lang="scss" scoped>
  

</style>