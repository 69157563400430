
<template>
    <Topbar title="Nowy lek"/>
 
    <div class="app__content__wrapper">
      <div class="row">
     <div class="col-md-6">
        <Box title="Dodaj lek">

          <form action="" @submit="onFormSubmit">
      
                <div class="form-field">
                  <label class="form-label"  for="">Tytuł</label>
                  <input  v-model="title"  cols="30" rows="10"/>
                </div>
                <div class="form-field" v-if="false">
                  <label class="form-label"  for="">Nazwa leku </label>
                  <TCheckboxes name="typeOfDrug" inline="true" v-model="typeOfDrugModel" :data="typeOfDrugData"/>
                </div>

                <div class="form-field" v-if="false">
                  <label class="form-label"  for="">Opis </label>
                  <textarea  v-model="desc"  cols="30" rows="10"></textarea>
                </div>

                <div class="text-right">
                  <button type="submit" class="btn btn--primary btn--large">Dodaj lek</button>
                </div>
             
           
          
      
    
        </form>
        </Box>
      </div>
     </div>

    </div>

</template>

<script>
import { format, addMinutes } from 'date-fns';
import Topbar from '@/components/Topbar.vue';
import FormFooter from '@/components/FormFooter.vue';
import TSelect from '@/components/form/TSelect.vue';
import TCheckbox from '@/components/form/TCheckbox.vue';
import TCheckboxes from '@/components/form/TCheckboxes.vue';
import TRadios from '@/components/form/TRadios.vue';
import Box from '@/components/Box.vue';
import {ref, inject, onMounted, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router'
export default {
  name: 'AdminDrugsAddView',
  components: {
    Topbar,TSelect,TCheckbox,Box,TCheckboxes,TRadios,FormFooter
  },
  emit: ['alert'],
   setup(props,{emit}) {
    const axios = inject('axios');
    const router = useRouter();
    const footerHeight = ref(100);
    const desc = ref('');
    const title = ref('');
    let typeOfDrugModel = ref([]);
    let typeOfDrugData = ref();
       onMounted( async() => {
        await axios.get('/dosage-forms')
        .then(response => {
          let data = response.data.data.map(type =>  { 
            
            return {
              value: type.id,
              text: type.name,
            }
          })

          if(data.length){
            typeOfDrugData.value = data;
            typeOfDrugModel.value = [data[0].value]
          }
       
        });
      });


  async function onFormSubmit(evt){
    evt.preventDefault();
    let data = {
      "name": title.value,
      // "description": desc.value,
      "dosageForms": typeOfDrugModel.value,
    }
 
    await axios.post('/admin/medications',data)
      .then(response => {
        emit('alert',{
                type: 'success',
                title: 'Lek został dodany do systemu!',
                visible: true,
                autoClose: true,
                data: []
              } );
        router.push({ path: '/admin'});
      })
      .catch(err => {
      
          emit('alert',{
                type: 'error',
                title: 'Wystąpił problem z zapisem leku',
                data: [err.response.data.error.message],
                visible: true,
           } );
      });
      
    
   }
   
  
    return {footerHeight,desc,title,typeOfDrugData, typeOfDrugModel,onFormSubmit};
  },
}
</script>


<style lang="scss" scoped>
  

</style>