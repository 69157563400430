<template>
     <div >
        <div class="auth-wrap">
        

        <Box class="auth-box"> 
            <header class="auth-box__header">
              <h1 class="auth-box__title">Rejestracja</h1>
              <img :src="logo" alt="Hily logo" class="auth-box__logo" >
            </header>

          <form  @submit.prevent="onCreateAccount" autocomplite="off">

            <div class="form-field" v-if="false">
                  <label class="form-label"  for="">Nazwa użytkownika</label>
                  <input v-model="userName" autocomplete="nope" type="text" name="username" id="">
          </div>

          <div class="form-field">
                  <label class="form-label"  for="">Adres email</label>
                  <input v-model="email" autocomplete="nope" type="email" name="email" id="">          
          </div>
          <div class="form-field">
                  <label class="form-label"  for="">Haslo</label>
                  <input v-model="password" autocomplete="nope" type="password" name="password" id="">
          </div>

          <div class="form-field">
            <TCheckboxes name="rodo2"  v-model="rodoModel" 
            :data="[
              {value:'terms', text: `Akceptuje regulamin <a target='_blank' href='https://hily.pl/regulamin-aplikacja'>Link</a>`},
              {value:'rodo', text: `Akceptuje politykę prywatności <a target='_blank' href='https://hily.pl/polityka-prywatnosci-aplikacja'>Link</a>`}
              ]"/>
          </div>
        
       

            <button type="submit" class="btn btn--primary btn--full">Zarejestruj się</button>
          </form>
          <footer class="auth-box__footer">
              <nav class="auth-box__nav">
                  <router-link class="auth-box__nav__item" to="/logowanie">Masz już konto? Zaloguj się</router-link>
              </nav>
          </footer>
        </Box>
      </div>
    </div>
</template>

<script>

import {inject,ref} from 'vue';
import Topbar from '@/components/Topbar.vue';
import CustomChart from '@/components/CustomChart.vue';
import TCheckboxes from '@/components/form/TCheckboxes.vue';
import Box from '@/components/Box.vue';
import { useRouter, useRoute } from 'vue-router';
import hily_logo from '@/assets/hily_logo.svg';


export default {
  name: 'RegisterView',

  components: {
    CustomChart,Topbar,Box,TCheckboxes
  },
  setup(props, {emit}) {
    const axios = inject('axios');
    let email = ref('');
    let password = ref('');
    let userName = ref('');
    let rodoModel = ref([]);
    let logo= ref(hily_logo);
    const router = useRouter();
    if(localStorage.getItem('token')){
      router.push('/');
    }
    function onCreateAccount() {
      const that = this;
      emit('removeAlerts');
      if(rodoModel.value.length == 2){
        axios.post('/users/signup', { email: email.value, password: password.value, name: userName.value})
        .then(response => {
          emit('alert',{
                  type: 'success',
                  title: 'Konto zostało założone, możesz się zalogować',
                  data: [],
                  visible: true,
              } );
          
          router.push({ path: '/logowanie', replace: true })
        }).catch(err => {

          emit('alert',{
                  type: 'error',
                  title: 'Wystąpił z rejestracją',
                  data: [err.response.data.error.message],
                  visible: true,
              } );
          if (err.response.status == 400) {
            // that.$q.notify({
            //   timeout: '2000',
            //   position: 'top',
            //   type: 'negative',
            //   message: err.response.data.error
            // })
          } else {
            // that.$q.notify({
            //   position: 'top',
            //   timeout: '2000',
            //   type: 'negative',
            //   message: 'Wystąpił nieznany problem. Spróbuj ponownie później.'
            // })
          }
        })
      }else{
        emit('alert',{
                  type: 'error',
                  title: 'Wystąpił z rejestracją',
                  data: ['Musisz zaakceptować zgody'],
                  visible: true,
              } );
      }
      

    };
    return {onCreateAccount,userName,email,password,logo,rodoModel};
  },
}
</script>

