<template>
 <div class="alert" :class="`alert--${type}`">
    <button @click="onClose" class="alert__close">X</button>
    <h3 class="alert__title">{{ title }}</h3>
    <div class="alert__content">
        <ol v-if="data.length" class="alert__list" >
            <li class="alert__list__item" :key="index" v-for="(item,index) in data">
              {{ item }}
            </li>
        </ol>
    </div>
 </div>
</template>

<script>

export default {
  name: 'Alert',
  props: {
    type: String,
    class: String,
    title: String,
    data: Object,
    
  },
  emit: ['updateVisible'],
  setup(props, {emit}){

    function onClose(){
      console.log('cliku');
      emit('updateVisible', false);
    }
    return {onClose};
  }
}
</script>


<style lang="scss" scoped>
  .alert{
    position:fixed;
    top: 30px;
    right: 30px;
    z-index: 999999999999999;
    width: 400px;

    @media(max-width: 500px){
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
    }
    padding: 20px;
    
    color: white;

    &__title{
      font-size: 15px;
      margin-top: 0;
      padding-right: 30px;
      margin-bottom: 10px;
    }

    &__close{
      background: transparent;
      border-width: 0;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &__list{
      font-size: 13px;
      padding-left: 10px;
    }

    &--error{
      background-color: darken($secondary_color,20%);;
    }
    &--success{
      background-color: $primary_color;
    }
  }
</style>