<template>

    <div  class="calendar" :class="{'calendar--empty': !anyEvents}" @mousedown="onMouseDown"
      @mouseup="onMouseUp">
        <span v-if="!anyEvents" class="calendar-empty__text">Brak danych w tym zakresie dat</span>
        <div class="calendar__hours">
      
          <div class="calendar__hour" :style="{height: cssGap + 'px'}" v-for="(gap,index) in gapsCount" :key="index">
            {{ timeGapConverter(index) }}
          </div>
        
        </div>
        
        <div class="calendar__days" ref="calRef">
          <div class="calendar__gaps"  :style="{height: `calc(100% + ${cssGap}px)`,width: ` ${calRef}px`}">
            <span class="calendar__gap" :style="{height: cssGap + 'px'}" v-for="(gap,index) in gapsCount" :key="index"></span>
    
          </div>
            
          <CalendarDay v-for="(day, index) in dayData" :key="index" :name="day.name" :date="day.date">
            <CalendarEvent 
              v-for="(event, id) in day.events" 
              :key="id" 
              :type="event.type" 
              :desc="event.desc" 
              :typeName="event.typeName" 
              :timeStart="event.timeStart" 
              :timeEnd="event.timeEnd"
              :position="calculateEvtPosition(event.timeStart)"
              :size="calculateEvtSize(event.timeStart,event.timeEnd)"
              :id="event.id" 
              @onFocus="evt => onFocusEvent(evt,event)"
              :needActive="activeEventId != null"
              :active="event.id == activeEventId && event.type == activeEventType"
            />
          </CalendarDay>
       
        </div>

       
   </div>
   <Sidebox ref="sidebox" @deleteEvent="deleteEvent" :type="activeEventType" :data="activeEventData" :open="isOpenSidebox"  @onExit="onExitSidebox" :visitMode="visitMode" />



</template>

<script>
import {ref, watch, onMounted, onDeactivated} from 'vue';
import CalendarDay from '@/components/calendar/CalendarDay.vue';
import CalendarEvent from '@/components/calendar/CalendarEvent.vue';
import Sidebox from '@/components/Sidebox.vue';

export default {
  name: 'Calendar',
  props: {
    length: {
      type: Number,
      default: 7
    },
    timeStart: String,
    timeEnd: String,
    dayData: Array,
    anyEvents: Boolean,
    visitMode: {
      type: Boolean,
      default: false
    },

    gap: {
      type: Number,
      default: 30
    },

    cssGap: {
      type: Number,
      default: 125
    },
  },
  components: {
    CalendarDay,CalendarEvent,Sidebox
  },

  setup(props, {emit}){
   let  isDragging = ref(false);
   let cursorPos =  ref([0, 0]);
   let calRef = ref(null);
   let isOpenSidebox = ref(false);
   const sidebox = ref(null);
   var timeRange = ref();
    var gapsCount = ref();
   let activeEventId = ref(null);
   let activeEventType = ref(null);
   let activeEventData = ref(null);
   let timeStartHour = ref();
   let timeStartMinute = ref();
   let timeEndMinute = ref();
   let timeEndHour = ref();
   const defaultStartTime = '08:00';
   const defaultEndTime= '10:00';

    function factorNewData(){
      if(typeof props.timeStart != 'undefined' && typeof props.timeEnd != 'undefined'){
        [timeStartHour.value, timeStartMinute.value] = convertStringHourToArray(props.timeStart);
         [timeEndHour.value, timeEndMinute.value] = convertStringHourToArray(props.timeEnd);
      }else{

       if(props.anyEvents){
        var callendarRange = getMinMaxEventHours();
       [timeStartHour.value, timeStartMinute.value] = convertMinutesToHours(convertHoursToMinutes(callendarRange.min)  );
        [timeEndHour.value, timeEndMinute.value] = convertMinutesToHours(convertHoursToMinutes(callendarRange.max) );;
  
       }else{
        [timeStartHour.value, timeStartMinute.value] = convertStringHourToArray(defaultStartTime);
        [timeEndHour.value, timeEndMinute.value] = convertStringHourToArray(defaultEndTime);
       }
        
      }

        timeRange.value = ((timeEndHour.value * 60 + timeEndMinute.value)  -  (timeStartHour.value * 60 + timeStartMinute.value)) + props.gap;
        gapsCount.value = Math.ceil(timeRange.value / props.gap);
    }

  
    watch(()=> props.dayData,   factorNewData);
  
    onMounted(() => {
      window.addEventListener("mouseup", onMouseUp);
    });

    onDeactivated(() => {
      window.removeEventListener("mouseup", onMouseUp);
    });

    function onMouseDown(ev) {
      cursorPos.value = [ev.pageX, ev.pageY];
      isDragging.value = true;
     
      window.addEventListener("mousemove", onMouseHold);
    };


    function onMouseUp(ev) {
   ``
      window.removeEventListener("mousemove", onMouseHold);
      isDragging.value = false;
    };


    function onMouseHold(ev) {
      ev.preventDefault();

    
        const delta = [
          ev.pageX - cursorPos.value[0],
          ev.pageY - cursorPos.value[1],
        ];

        cursorPos.value = [ev.pageX, ev.pageY];
        // if (!calRef.value) return;
        calRef.value.scrollBy({
          left: -delta[0],
          // top: -delta[1],
       
      });
    };

    function timeGapConverter(index){
      let minutes = timeStartMinute.value + index * props.gap;

      let timeToAdd =  convertMinutesToHours(minutes);

      let finalTime = [timeStartHour.value + timeToAdd[0], timeToAdd[1]];

      if(finalTime[0] >= timeEndHour.value &&  finalTime[0] > timeEndMinute.value ){
        return `${timeEndHour.value}:${niceMinutesNotation(timeEndMinute.value)}`;
      }

      return `${finalTime[0]}:${niceMinutesNotation(finalTime[1])}`;
    }
   
   function convertMinutesToHours(minutes) {
      let hours = Math.floor(minutes / 60);
      let restOfMinutes = minutes % 60;
      return [hours,restOfMinutes];
    }

    function convertHoursToMinutes(arr) {
      return arr[0]*60 + arr[1];
    }

   function convertStringHourToArray(strHour){
    return strHour.split(':').map(Number);
   }
    function niceMinutesNotation(minutes){
    
      if(minutes == 0) return '00';
      else{
        if(minutes < 10) return '0' + minutes.toString();

        return minutes;
      }
    }

    function calculateEvtPosition(eventStart){
      let [eventStartHour, eventStartMinute] = convertStringHourToArray(eventStart);
      let eventStartInMinutes = (eventStartHour - timeStartHour.value) * 60 + (eventStartMinute - timeStartMinute.value);
      
     
      let gapPart =  eventStartInMinutes * (props.cssGap / props.gap  );
      

      return gapPart;

    }

    
    function calculateEvtSize(eventStart, eventEnd){
      let [eventStartHour, eventStartMinute] = convertStringHourToArray(eventStart);
      let [eventEndHour, eventEndMinute] = convertStringHourToArray(eventEnd);
      let eventRangeInMinutes = (eventEndHour - eventStartHour) * 60 + (eventEndMinute - eventStartMinute);
      
   
      let gapPart =  eventRangeInMinutes * (props.cssGap / props.gap  );
      

      return gapPart;

    }

    function getMinMaxEventHours(){
      let min = [23,59];
      let max = [0,0];

      props.dayData.forEach(day => {
        day.events.forEach(event =>{
          let start = convertStringHourToArray(event.timeStart);
          let end = convertStringHourToArray(event.timeEnd);
         
          if(min[0] > start[0] ){
            min = start;
          }
          else if(min[0] == start[0]){
            if(min[1] >= start[1]){
              min = start;
            }
          }
         
          if(max[0] < end[0] ){
            max = end;
          }
          else if(max[0] == end[0]){
            if(max[1] <= end[1]){
              max = end;
            }
          }
         
        })
      });
      
      return{min,max};
    }

  
    function onFocusEvent(evt, data){
   
      activeEventId.value = evt.id;
      activeEventType.value = evt.type;
      activeEventData.value = data;
     
      isOpenSidebox.value = true;
      // sidebox.value?.scrollTop();

      
    }
    function onExitSidebox(){
      isOpenSidebox.value = false;
      activeEventId.value = null;
      setTimeout(() => {
        activeEventType.value = null;
      }, 500);
      
    }
    function deleteEvent(data){
    emit('deleteEvent', {id:data.id, type:data.type});
      isOpenSidebox.value = false;
      activeEventId.value = null;
      setTimeout(() => {
        activeEventType.value = null;
      }, 500);
  }
 
    return {deleteEvent,calRef,onMouseDown, onMouseUp, activeEventData,activeEventId,activeEventType,sidebox,onExitSidebox,isOpenSidebox,timeRange,gapsCount,timeGapConverter,calculateEvtPosition,calculateEvtSize,onFocusEvent};
  }
}
</script>


<style lang="scss" scoped>

</style>