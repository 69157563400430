<template>
  <div class="chart-stat-wrapper">
    <div class="chart-stat">
      <span class="chart-stat__title">{{ title }}</span>
      <div class="chart-stat__content">
        <span :style="{color: valueColor}" class="chart-stat__value">
          {{ value }} <span class="chart-stat__value__unit">{{ unit }}</span>
        </span>
        <svg class="chart-stat__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="185.309px" height="84.574px" viewBox="0 0 185.309 84.574" enable-background="new 0 0 185.309 84.574"
            xml:space="preserve">
            <g class="chart-stat__icon__border">
              <path fill="transparent" :stroke="border" stroke-width="2" stroke-miterlimit="10" d="M185.684,0c0,0-5.103,13.391-8.163,18.493
                s-17.286,12.505-17.286,12.505h-14.347c0,0,0-0.255-5.103,6.888c-5.103,7.144,0,12.557,0,12.557l-22.449,0.454
                c0,0-4.08-1.021-12.102,0c-8.02,1.021-8.305,4.082-11.366,5.102S83.645,71.304,80.583,72.835
                C77.522,74.365,64.259,61.1,61.197,61.1s-16.322,7.144-16.322,7.144L34.679,78.45L0,84.574"/>
            </g>
            <g class="chart-stat__icon__background">
              <path :fill="bg" d="M185.459,0.212c0,0-4.99,13.285-8.051,18.387s-17.174,12.399-17.174,12.399h-14.347
                c0,0,0-0.255-5.103,6.888c-5.103,7.144,0,12.557,0,12.557l-22.449,0.454c0,0-4.08-1.021-12.102,0
                c-8.02,1.02-8.305,4.081-11.366,5.102c-3.061,1.021-11.224,15.307-14.285,16.838C77.522,74.365,64.259,61.1,61.197,61.1
                s-16.322,7.144-16.322,7.144L34.679,78.662L0,84.998h185.234L185.459,0.212z"/>
            </g>
        </svg>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ChartStat',
  props: {
    title: String,
    valueColor: String,
    value: String,
    unit: String,
    border: String,
    bg: String,
  }
}
</script>


<style lang="scss" scoped>
.chart-stat{
  border: 1px solid #ebebeb;
  border-radius: 30px;
  padding: 15px;
  width: 100%;
  font-size: 17px;
  &__title{
    font-weight: 700;
    color: #393939;
    font-size: 1em;
  }

  &__value{
    font-size: 1.9em;
    font-weight: 700;
    line-height: 1;
    &__unit{
      font-size: .6em;
    }
  }
  &__content{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__icon{
    width: 70px;
    height: auto;
    margin-left: 10px;
    
  }
}
</style>