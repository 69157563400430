<template>
 <div class="today">
    <span>
      Dzisiaj
    </span>
    <date class="today__date">{{ currentDate }}</date>
 </div>
</template>

<script>

export default {
  name: 'Today',
  props: {
    msg: String
  },

  setup(){
    let date = new Date();

      let day = date.getDate() < 10 ? '0'+ date.getDate() :  date.getDate();
      let month = date.getMonth() + 1 < 10 ? '0'+ (date.getMonth() + 1) :  date.getMonth() + 1;
      let year = date.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
      let currentDate = `${day}.${month}.${year}`;
    return {currentDate};
  }
}
</script>


<style lang="scss" scoped>
  .today{
    span{
      display: block;
      font-size: 15px;
    }

    &__date{
      color: #031b61;
      font-weight: bold;;
      font-size: 26px;
    }

    @media(max-width: 1024px){
      &__date{
        font-size: 17px;
      }
 

    }

    @media (max-width: 768px){
      span{
        font-size: 11px;
      }
      &__date{
        font-size: 13px;
      }
    }
  }
</style>