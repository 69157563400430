
<template>
    <Topbar title="+Podanie IgG"/>
 
    <div class="app__content__wrapper" >

      <Box title="Dodaj">
        <form action="" @submit="onFormSubmit" >
          <div class="row">
            <div class="col-md-6">
              <div class="form-field">
                <label class="form-label"  for="">Tytuł</label>
                <input  v-model="title"  cols="30" rows="10"/>
              </div>
            

                  <div class="form-field ">
                    <label class="form-label"  for="">Zaznacz leki</label>
                    <TRadios inline v-show="drugsData.length" v-model="drugsModel"  :data="drugsData.map(item=>{return{value: item.id, text: item.name}})"/>
                  </div>
                  
                  <div v-show="selectedDrugs.length" class="selected-drugs-fields">
                    <div class="selected-drugs-fields__item" v-for="(item,index) in selectedDrugs" :key="index">
                      <label for="" class="selected-drugs-fields__item__title">{{ item.name }}</label>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-field ">
                            <label class="form-label"  for="">Dawka</label>
                            <input type="text" v-model="selectedDrugsModels[drugsModelFindIndex(item.id)].amount">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-field ">
                            <label class="form-label"  for="">Jednostka</label>
              
                            <TSelect v-model="selectedDrugsModels[drugsModelFindIndex(item.id)].dosageUnitId" setupText="Wybierz jednostkę" :data="generateSelect(item.dosages)"></TSelect>
                          </div>
                        </div>
                      </div>
                
                     
                    </div>

         
          
               </div>
             

            

            </div>
            <div  class="col-md-6">
              <VDatePicker ref="calendarPicker" :rules="rules" mode="dateTime" is24hr hide-time-header   trim-weeks  :isRange="false" title-position="left" borderless  v-model="date" :max-date="new Date()"/>
           
                  <label class="form-label">Czas podania leku</label> 
                  <div class="form-field ">
                    <label class="form-label"  for="">Godziny</label>
                    <TSelect v-model="hour" setupText="Godziny" :data="[{value: 0, text: '0'},{value: 1, text: '1'},{value: 2, text: '2'},{value: 3, text: '3'},{value: 4, text: '4'}]"></TSelect>
                  </div>
                  <div class="form-field form-field">
                    <label class="form-label"  for="">Minuty</label>
                    <TSelect v-model="minutes" setupText="Minuty" :data="[{value: 0, text: '0'},{value: 15, text: '15'},{value: 30, text: '30'},{value: 45, text: '45'},]"></TSelect>
                  </div>
          
              <div class="form-field">
                <label class="form-label"  for="">Opis </label>
                <textarea  v-model="desc"  cols="30" rows="10"></textarea>
              </div>


            </div>
          </div>
         
     
          <FormFooter :height="footerHeight">
            <button type="submit" class="btn btn--primary btn--large">Dodaj</button>
          </FormFooter>
          </form>

      </Box>
     

    </div>

</template>

<script>
import { format, addMinutes } from 'date-fns';
import Topbar from '@/components/Topbar.vue';
import FormFooter from '@/components/FormFooter.vue';
import TSelect from '@/components/form/TSelect.vue';
import TCheckbox from '@/components/form/TCheckbox.vue';
import TCheckboxes from '@/components/form/TCheckboxes.vue';
import TRadios from '@/components/form/TRadios.vue';
import Box from '@/components/Box.vue';
import {ref, inject, onMounted, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useFooterGap} from '@/hooks/useFooterGap';
export default {
  name: 'TakeMedicineView',
  components: {
    Topbar,TSelect,TCheckbox,Box,TCheckboxes,TRadios,FormFooter
  },
  props: {
    formFooter: Boolean,
  },
  emit: ['alert'],
   setup(props,{emit}) {
    useFooterGap(props.formFooter,emit);
    const axios = inject('axios');
    const router = useRouter();
    let date = ref(new Date());
    const rules = ref({
      minutes: [0,5,10,15,20,25,30,35,40,45,50,55],
  
    });
    const footerHeight = ref(100);
    let testsFields = ref({})
    let place = ref('');
    let desc = ref('');
    let hour = ref('');
    let minutes = ref(30);
    let locationAddress = ref('');
    let doctor = ref('');
    let title = ref('');
    let testType = ref([]);
    let testData = ref([]);
    let testTypeSelected = ref(0);

    let selectedData = ref([]);
    //checkbox
    // let drugsModel = ref([]);

    //radio
    let drugsModel = ref('');


    let drugsCheckboxData = ref([]);
    let drugsData = ref([]);
    let selectedDrugs = ref([]);
    let selectedDrugsModels = ref([]);
    watch(drugsModel, newVal =>{

      //checkbox
      // let filterData = drugsData.value.filter(item=> newVal.includes(item.id));

      //radio
      let filterData = drugsData.value.filter(item=> newVal == item.id);


      selectedDrugs.value = filterData;
    //Checkbox
      // if(!newVal.length){
      //   selectedDrugsModels.value= [];
      // }
      
      // radio
      if(newVal == ''){
        selectedDrugsModels.value= [];
      }

      if(!selectedDrugsModels.value.length){
      
        selectedDrugsModels.value = filterData.map(item=> {
          return{
            drugId: item.id,
            amount: ref(''),
            // dosageUnitId: ref(''),
            dosageUnitId: ref(item.dosages[0].units[0].id),
          }
        });
      }else{
     
          filterData.forEach(item =>{
            let index = selectedDrugsModels.value.findIndex(selected => selected.drugId == item.id);
            if(index == -1){

              selectedDrugsModels.value.push({
                drugId: item.id,
                amount: ref(''),
                // dosageUnitId: ref(''),
                dosageUnitId: ref(item.dosages[0].units[0].id),
              });
          
            }
          })

          selectedDrugsModels.value.forEach((item, item_index) =>{
            let index = filterData.findIndex(filter => filter.id == item.drugId);
            if(index == -1){
              selectedDrugsModels.value.splice(item_index,1);
            }
          })

         
      }
    
    });

    function drugsModelFindIndex(id){
      return selectedDrugsModels.value.findIndex(selected => selected.drugId == id);
    }

    const placeData = [{text: 'Szpital', checked: true}, {text: 'Dom'}, {text: 'Poradnia'}];
 
    onMounted( async() => {
        await   getData() 

      });

      async function getData() {
        await axios.get('/drugs')
        .then(response => {

          drugsData.value = response.data.data.map(item => {return {
            id: item.id,
            name: item.medication.name,
            dosages: [item.dosageForm]
          }})
          
         
        });
      }


    watch(testTypeSelected, newVal =>{
      selectedData.value = testData.value.filter(item=> newVal == item.id)[0]
    });
    watch(selectedData, newVal =>{
      testsFields.value= {};

      newVal.data.forEach(item => {
        testsFields.value[item.uId] = ref('');
      });
     
    });
   
    function onChangeTestSelect(event) {
      testTypeSelected.value = event;
    };
    function generateSelect(data){
       let tempData = [];
      
      data.forEach(dosage =>{
        let units = dosage.units;

        units.forEach(unit =>{
          tempData.push({
       
              value: unit.id,
              text: unit.name
        
          })
        })
      })


   

       return tempData;
   }
    function  onChangePlace(event) {
    
        place.value = event;
    };

   async function onFormSubmit(evt){
    evt.preventDefault();
    var errorData = [];

    if(!title.value.length){
      errorData.push('Pole z tytułem jest puste');
    }
    if(!date.value){
      errorData.push('Nie wybrano daty');
    }
    if(minutes.value == 0 && hour.value == 0){
      errorData.push('Czas trwania jest równy 0');

    }

    if(!selectedDrugsModels.value.length){
      errorData.push('Nie wybrano żadnego leku');

    }else{
      let errorType = false;
      let errorEmpty = false;
      var dosagesData = selectedDrugsModels.value.map(item => {
        if(typeof Number(item.amount) == 'number' && !isNaN(Number(item.amount))){
        } else {
          errorType = true;
        }

        if(!item.amount.length){
          errorEmpty = true;
        }

        return{
              drugId: item.drugId,
              amount: Number(item.amount),
              dosageUnitId:item.dosageUnitId,
        }
      });

      if(errorType){
        errorData.push('Dawka leku musi być wartością liczbową');
      }
      if(errorEmpty){
        errorData.push('Dawka leku nie może być pusta');
      }

      

    }


    let data = {
      "title": title.value,
      "description": desc.value,
      // "date": new Date(date.value),
      "startDate": new Date(date.value),
      "endDate": new Date(addMinutes(date.value, hour.value * 60 + minutes.value )),
      "dosages": dosagesData
      
    }


  
    if(errorData.length){
        emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z zapisem podania IgG',
                  data: errorData,
                  visible: true,
              } );
    }else{
      await axios.post('/drug-intakes/',data)
        .then(response => {
          emit('alert',{
                  type: 'success',
                  title: 'Podanie IgG zostało dodane do systemu!',
                  visible: true,
                  autoClose: true,
                  data: []
                } );
          router.push({ path: '/kalendarz', query: { date: format(date.value, 'yyyy-MM-dd')}} );

        
        })
        .catch(err => {
          emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z podaniem IgG',
                  data: [err.response.data.error.message],
                  visible: true,
             } );

        
        });
        
    }
   }
    return {generateSelect,drugsModelFindIndex,selectedDrugsModels,selectedDrugs,drugsData,drugsCheckboxData,drugsModel, doctor,title, footerHeight,  locationAddress,hour,minutes,desc,onFormSubmit,testsFields,place,placeData,date,rules,testType,testData,testTypeSelected,selectedData,onChangeTestSelect,onChangePlace};
  },
}
</script>


<style lang="scss" scoped>
  

</style>