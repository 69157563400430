<template>
    <Topbar :title="`${  formatRange.start } - ${formatRange.end}`"/>
    <div class="app__content__wrapper">
    
        <div class="chart-range">
          <TRadios name="chartRange" class="chart-range__buttons" inline="true" v-model="rangeModel" :data="rangeData"/>

          <div class="chart-range__callendar" v-show="rangeModel == 0 ">
            <button class="btn btn--primary" @click="onOpenDatePicker">Zmień datę</button>

            <div class="picker-container" v-show="datePickerOpen">
              <div class="picker-wrapp"  >
              <VDatePicker ref="calendarPicker"  trim-weeks  :max-date="maxRanges.max"    :isRange="true" title-position="left" borderless  v-model.range="datePickerRange"/>
              
            </div>
            </div>
          
          </div>
        </div>
   
    
        <CustomChart :emptyDrugIntakesData="emptyDrugIntakesData" :emptyMedicalTestData="emptyMedicalTestData" :range="rangeToChart" :data="data" :drugDifference="drugDifference" :drugNames="drugNames"/>
    </div>
   

</template>

<script>

import {inject,ref,onMounted,watch} from 'vue';
import Topbar from '@/components/Topbar.vue';
import TRadios from '@/components/form/TRadios.vue';
import CustomChart from '@/components/CustomChart.vue';
import { format, subDays,addDays,differenceInCalendarDays,set,add,isEqual,parseISO,subYears, sub } from 'date-fns';
import { useRouter, useRoute } from 'vue-router';
import {useFooterGap} from '@/hooks/useFooterGap';


export default {
  name: 'ViewersHomeView',
  props: {
    formFooter: Boolean,
  },
  components: {
    CustomChart,Topbar,TRadios
  },
  setup(props,{emit}) {
    useFooterGap(props.formFooter,emit);
    const axios = inject('axios');
    const router = useRouter();
    const route = useRoute();
    let rangeModel = ref(3);
    let rangeData = ref([{value: 3, text: '3 miesiące'}, {value: 6, text: '6 miesięcy',},{ value: 12, text: 'Rok'},{value: 0, text: 'Niestandardowe'}]);
    let datePickerOpen = ref(false);
    const range = ref({
          start:  sub(new Date(), { months: rangeModel.value }), 
          end:  new Date(),
      });
      const datePickerRange = ref({
          start:  range.value.start,
          end:   range.value.end,
      });

    const formatRange = ref({
      start: format(range.value.start, 'dd.MM.Y'),
      end: format(range.value.end, 'dd.MM.Y'),
    })
    const maxRanges = ref({
        min: set(new Date(),{month: 0, date: 1,}),
        max: new Date()
      })
    let rangeToChart = ref(range.value);
    let drugDifference = ref([]);
    let drugNames = ref({});
    let emptyMedicalTestData = ref(true);
    let emptyDrugIntakesData = ref(true);
 
    watch(rangeModel, newVal =>{
  
      if(newVal !=0){
        range.value ={
          start:  sub(new Date(), { months: newVal}), 
          end:  new Date(),
        }
      }
      
    });
    let data= ref ([
      // {
      //   id: 1,
      //   name: 'Wartość 1',
      //   data: [.2, null, .3, .4, .3, .2,.2,.1,.2],
      //   unit: 'mIU/l'
      // },
      // {
      //   id: 2,
      //   name: 'Wartość 2',
      //  data: [0, 10, 30, 40, 50, 30,20, 10, 0,20, 10, 0],
      //   unit: '%'
      // },
      // {
      //   id: 3,
      //   name: 'Wartość 3',
      //   data: [.7, .4, .2, .1, .8, .8,.9,.4,.3],
      //   unit: 'mIU/l'
      // },
      // {
      //   id: 4,
      //   name: 'Wartość 4',
      //   data: [0, 120, 230, 44, 54, 34,24, 14, 4],
      //   unit: '%'
      // },
    ]);

    // if(!localStorage.getItem('token')){
    //   router.push('/logowanie');
    // }
    watch(range, async newVal =>{

      await getData();
      rangeToChart.value = newVal;
      datePickerRange.value = newVal;
      formatRange.value = {
        start: format(range.value.start, 'dd.MM.Y'),
        end: format(range.value.end, 'dd.MM.Y'),
      };
    });
   
    watch(datePickerRange, async newVal =>{
 
      range.value = newVal;
    });
      onMounted(async() => await getData())
    async function getData (){
       
        await axios.get(`/viewers/${route.params.uId}/numerical-events`,{params:{start: format(range.value.start, 'yyyy-MM-dd'), end:format(range.value.end, 'yyyy-MM-dd')}})
        .then(response => {
          let len = differenceInCalendarDays( range.value.end,range.value.start);
          emptyMedicalTestData.value = !response.data.data.medicalTest.results.length ;
          emptyDrugIntakesData.value = !response.data.data.drugIntakes.length ;
        
          let labels = [];
          let medicalTestData = []; 
          let drugIntakes = []; 
          let tempDrugDifference = []; 
          let tempDrugNames = {}; 
          for(let i = 0 ; i <= len; i++){
          
            let date = addDays(range.value.start, i);
            let elemMedic = response.data.data.medicalTest.results.find(item => isEqual(parseISO(format(date,'yyyy-MM-dd')), parseISO(format(new Date(item.startDate),'yyyy-MM-dd'))));
            if(typeof elemMedic != 'undefined'){
              medicalTestData.push(elemMedic.measurements[0].baseValue);
            }else{
              medicalTestData.push(null);
            }

              let elemDrug = response.data.data.drugIntakes.find(item => isEqual(parseISO(format(date,'yyyy-MM-dd')), parseISO(format(new Date(item.startDate),'yyyy-MM-dd'))));
              if(typeof elemDrug != 'undefined'){
                drugIntakes.push(elemDrug.dosages[0].amount);
                tempDrugDifference.push( elemDrug.dosages[0].drugId);
                tempDrugNames[elemDrug.dosages[0].drugId] = elemDrug.dosages[0].drugName;
                

              }else{
                drugIntakes.push(null);
                tempDrugDifference.push(null);
              }

         
          }
        
          // let tempData = response.data.data.medicalTest.results.map(item=>{
          //   return item.measurements[0].value;
            
          // });
          let temp = [];
            
            if(!medicalTestData.every(item => item == null)){
              temp.push({
                id: 'medicalTest',
                name: response.data.data.medicalTest.schema.metrics[0].name,
                data: medicalTestData,
                unit: response.data.data.medicalTest.schema.metrics[0].unit.name,
                axis: 'y'
              });
       
            }
            
            
            if(!drugIntakes.every(item => item == null)){ 
              temp.push({
                id: 'drugsIntakes',
                name: '+Wynik IgG',
                data: drugIntakes,
                unit: 'g',
                axis: 'y1'
              });
          
            }
            
            
            
        



         
      

          data.value = temp;
          drugDifference.value = tempDrugDifference;
          drugNames.value = tempDrugNames;

       
        })
        .catch(err => {
       
        });
      }

      function onOpenDatePicker(){
        datePickerOpen.value = !datePickerOpen.value
      }
    return {data,drugDifference,drugNames,range,rangeData, rangeModel,rangeToChart,datePickerRange,formatRange,datePickerOpen, onOpenDatePicker,maxRanges,emptyDrugIntakesData,emptyMedicalTestData};
  },
}
</script>



<style lang="scss">

.chart-range{
 
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__callendar{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    .btn{
      margin-bottom: 10px;
    }
    .picker-container{
      position: relative;
    }
    .picker-wrapp{
      position: absolute;
      top: 0;
      right:0;
    }

    .vc-pane-container {
      z-index: 99;
    }
  }
}
  .chart-range__buttons{

    .t-radio{
      padding: 0 !important;
      margin-left: 15px;
      @media(max-width: $md){
        margin-left: 5px; 
        }
      label{
        padding: 8px 20px !important;  
        border: 1px solid $border-color;
        &::before{
          content: none !important;
        }
        &.active{
          background-color: $secondary-color;;
          border-color: $secondary-color;
          font-weight: 600;
        }

        @media(max-width: $md){
          font-size: 12px !important;
          padding: 6px 10px !important;  
        }
      }
    
      
    }
  }
</style>