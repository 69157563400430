
<template>
  <Topbar title="+Podanie IgG">
    <router-link   v-if="false" to="/podanie-leku/dodaj" class="btn btn--secondary">Dodaj</router-link>
  </Topbar>

    <div class="app__content__wrapper">
    <div class="row">

      <div class="col-md-12"> 
        <Box title="Tabela +Podanie IgG">
          <header ref="tabRef" class="table-header">
            <div class="table-header__date-wrapper">
              <span class="table-header__date-range">
                {{format(range.start, 'dd.MM.Y')}} - {{format(range.end, 'dd.MM.Y')}}
              </span>
              <DateToggle :maxDate="new Date()" :isRange="true" v-model="range"/>

              <span class="table-header__page-status">
                Strona {{ currentPage }} z {{ totalPages }}
              </span>
            </div>
        
          </header>
          <table  class="base-table base-table--medical-takingMedicine" :class="{'base-table--mobile': viewport}" v-show="takeMedicineList.length">

            <thead>
              <th class="name">Nazwa</th>
              <th class="value">Dawka</th>
              <th class="desc">Opis</th>
              <th class="date">Data</th>
              <th class="action"  v-if="false">Akcja</th>
            </thead>

            <tbody>
              <tr v-for="(takingMedicine, index) in takeMedicineList" :key="index">
                <td class="name" data-name="Nazwa">
                  {{ takingMedicine.name }}
                </td>
                <td class="value" data-name="Dawka">
                  {{ takingMedicine.value }} {{ takingMedicine.unit }}
                </td>
                <td class="desc" data-name="Opis">
                    {{ takingMedicine.description.length ? takingMedicine.description : '---' }} 
                  
                </td>
                <td class="date" data-name="Data">
                  {{ takingMedicine.date }} 
                </td>
                <td class="action" v-if="false">
                  <div class="action__wrapper">
                    <router-link :to="`/podanie-leku/edytuj/${takingMedicine.id}`" class="btn btn--circle btn--primary btn--circle edit-drug"><img :src="editIcon"></router-link>
                    <button @click="onRemoveTakeMedicine(takingMedicine.id)" class="btn btn--secondary btn--circle remove-drug">X</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <h3 class="no-results" v-show="!takeMedicineList.length && !pending">Brak danych w tym zakresie </h3>
          <div class="text-center" v-if="totalPages > 1">
            <VueAwesomePaginate
              :total-items="totalCount"
              :items-per-page="15"
              :max-pages-shown="5"
              v-model="currentPage"
              :on-click="onChangePage"
            />
          </div>
       
        </Box>

      </div>
    </div>
   
   

  </div>
</template>

<script>
import { format, subYears } from 'date-fns';
import Topbar from '@/components/Topbar.vue';
import DateToggle from '@/components/DateToggle.vue';
import {useViewportChecker} from '@/hooks/useViewportChecker.js'
import BaseTable from '@/components/BaseTable.vue';
import Box from '@/components/Box.vue';
import {ref, inject, onMounted, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useFooterGap} from '@/hooks/useFooterGap';
import  edit_icon from '@/assets/edit_icon.svg' ;

export default {
name: 'ViewersTakeMedicineView',
components: {
  Topbar,Box,BaseTable,DateToggle
},
props: {
    formFooter: Boolean,
  },
emit: ['alert'],
 setup(props,{emit}) {
  useFooterGap(props.formFooter,emit);
  const axios = inject('axios');
  const tabRef = ref(null)
  const route = useRoute();
  let viewport = ref(useViewportChecker(992));
  let pending = ref(true);
  const editIcon = ref(edit_icon);
  let drugsList = ref([]);
  let takeMedicineList = ref([]);
  const recordPerPage = ref(15);
  let currentPage = ref(1);
  let totalCount = ref(1);
  let totalPages = ref(1);
  const range = ref({
    start: subYears(new Date(), 1),
    end: new Date(),
  });

  async function onChangePage (page){
    
    await   getData(page) 
  }
     onMounted( async() => {
      await   getData(currentPage.value) 

  });

    watch(range, async() => {
      await   getData(currentPage.value) 

  });
  // watch(totalCount, async (newVal) => {
    
  //    if(newVal <= recordPerPage.value &&  currentPage.value > 1){
     
  //     currentPage.value = 1
  //    }
      

  // });

    async function getData(page) {
      pending.value = true;
      await axios.get(`/viewers/${route.params.uId}/drug-intakes`,{
        params:{
          start: format(range.value.start, 'Y-MM-dd'),
          end: format(range.value.end, 'Y-MM-dd'),
          page: page,
        }
      })
      .then(response => {
        pending.value = false;
        currentPage.value = response.data.pagination.currentPage;
        totalCount.value = response.data.pagination.totalCount;
        totalPages.value = response.data.pagination.totalPages;
        takeMedicineList.value = response.data.data.map(takingMedicine =>{
          return{
            id: takingMedicine.id,
            name: takingMedicine.dosages[0].drugName,
            unit: takingMedicine.dosages[0].unitName,
            value: takingMedicine.dosages[0].amount,
            date: format(new Date(takingMedicine.startDate),'dd.MM.Y H:m'),
            description: takingMedicine.description
          };

        }); 
        tabRef.value.scrollIntoView({ behavior: "smooth" });
      }).catch(err =>{
        pending.value = false;

      });;;
    }


    async function onRemoveTakeMedicine(id){
      await axios.delete(`/drug-intakes/${id}`)
      .then( async response => {
        await   getData(1) ;

        emit('alert',{
              type: 'success',
              title: 'Podanie leku zostało usunięte z systemu',
              visible: true,
              autoClose: true,
              data: []
            } );
        
      });
    }
  return {drugsList,takeMedicineList,onRemoveTakeMedicine,editIcon,viewport,totalCount,currentPage,onChangePage,range,format,tabRef,totalPages,pending};
},
}
</script>

<style lang="scss" scoped>
.base-table{

 
    .desc{
     width: 50%;

     @media(max-width: 1500px){
      width: 35%;
     }
     
    }

}


</style>