<template>
  <table class="base-table" :class="class">
    <thead>
      <th :class="markup.id" v-for="(markup, index) in data" :key="index">{{markup.name}}</th>
    
    </thead>

    <tbody>
      <tr v-for="(itemData, id) in data" :key="id">
        <td :class="itemData.markup.id" :data-name="itemData.markup.name"  v-for="(itemData, index) in markupData" :key="index">
          {{ item.value }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  name: 'BaseTable',
  props: {
    class: String,
   
    data: Object,
    
  },
  emit: ['updateVisible'],
  setup(props, {emit}){

    function onClose(){
      console.log('cliku');
      emit('updateVisible', false);
    }
    return {onClose};
  }
}
</script>


<style lang="scss" scoped>
  .alert{
    position:fixed;
    top: 30px;
    right: 30px;
    z-index: 999999999999999;
    width: 400px;

    @media(max-width: 500px){
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
    }
    padding: 20px;
    
    color: white;

    &__title{
      font-size: 15px;
      margin-top: 0;
      padding-right: 30px;
      margin-bottom: 10px;
    }

    &__close{
      background: transparent;
      border-width: 0;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &__list{
      font-size: 13px;
      padding-left: 10px;
    }

    &--error{
      background-color: darken($secondary_color,20%);;
    }
    &--success{
      background-color: $primary_color;
    }
  }
</style>