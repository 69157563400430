<template>
  <div >
  <div class="auth-wrap">
    <Box class="auth-box"> 
      <header class="auth-box__header">
        <h1 class="auth-box__title">Aktywuj konto</h1>
        <img :src="logo" alt="Hily logo" class="auth-box__logo" >
      </header>

    <form action="" @submit.prevent="onSignIn">
            <label class="form-label"  for="">Podaj kod aktywujący konto, który znajdziesz na swojej skrzyce mailowej</label>
      <div class="form-field form-field--code">
            <input 
            v-for="(n,i) in 4"
              v-model="code[i]" 
        
                    @update:model-value ="(value) => inputCode(value , i) "
                      ref="c"
                      maxlength="1"
                      outlined
                      label=""
                      autocomplete="off"
                      :key="i"
                 
              type="text" 
              name="code" >
     </div>
  

    


    <button type="submit" class="btn btn--primary btn--full">Potwierdź rejestracje</button>
   </form>
   <footer class="auth-box__footer">
      <nav class="auth-box__nav">
          <button @click="getNewCode" class="btn btn--secondary">Generuj nowy kod</button>
      </nav>
   </footer>
  </Box>
  </div>

</div>
   

</template>

<script>

import { useRouter, useRoute } from 'vue-router';
import {inject,ref,computed,onMounted,onDeactivated} from 'vue';
import Topbar from '@/components/Topbar.vue';
import CustomChart from '@/components/CustomChart.vue';
import Box from '@/components/Box.vue';
import hily_logo from '@/assets/hily_logo.svg';



export default {
  name: 'RegisterAuthView',

  components: {
    CustomChart,Topbar,Box
  },
   emit: ['alert', 'removeAlerts'],
  setup(props, {emit}) {
    let code = ref(['','','','']);
    let c = ref(null);
    const axios = inject('axios');
    const router = useRouter();
    const logo = ref(hily_logo);
    if(localStorage.getItem('token')){
      router.push('/');
    }
    async function onSignIn() {
      emit('removeAlerts');
          const token = localStorage.getItem('registerToken');
          const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
       await axios.post('/user-account/verify-email', { confirmationCode: code.value.join('').replace(',','')}, config)
           .then(response => {
              
            localStorage.removeItem('registerToken');
            emit('alert',{
                  type: 'success',
                  title: 'Twoje konto zostało aktywowane, możesz się zalogować',
                  data: [],
                  visible: true,
              } );
              router.push('/logowanie') ;
             }).catch(err => {
              emit('alert',{
                  type: 'error',
                  title: 'Wystąpił błąd',
                  data: [err.response.data.error.message],
                  visible: true,
              } );
             });  
    };

    onMounted(() => {
      for(var i = 0 ; i <4 ;i++){
        c.value[i].addEventListener("paste", onPaste);
      }
      
    });

    onDeactivated(() => {
      for(var i = 0 ; i <4 ;i++){
        c.value[i].removeEventListener("paste", onPaste);
      }
    
    });

    function onPaste(event){
 
        event.preventDefault();

        let paste = (event.clipboardData || window.clipboardData).getData("text");
        paste = paste.toUpperCase().substr(0, 4).split("");

        
      code.value = paste;
    }
            
    function inputCode(e,i){
      code.value[i] = e.toUpperCase();
   
      if(e.length == 1 && Object.keys(c.value).length - 1 > i){
        c.value[i+1].focus();
      }
    }

    async function getNewCode(){
      emit('removeAlerts');

      const token = localStorage.getItem('registerToken');
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      axios.post('/user-account/send-email-confirmation-code', { }, config)
        .then(response => {
          const token = response.data.data.confirmationToken;
           localStorage.setItem('registerToken',token);
          emit('alert',{
                  type: 'success',
                  title: 'Wysłano nowy kod na maila',
                  data: [],
                  visible: true,
              } );

        }).catch(err => {

          emit('alert',{
                  type: 'error',
                  title: 'Wystąpił błąd',
                  data: [err.response.data.error.message],
                  visible: true,
              } );
        })
    }
    return {onSignIn, code,logo,inputCode,c,getNewCode};
  },
}
</script>

