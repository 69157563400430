<template>
     <div >
        <div class="auth-wrap">
        

        <Box class="auth-box"> 
            <header class="auth-box__header">
              <h1 class="auth-box__title">Reset hasła</h1>
              <img :src="logo" alt="Hily logo" class="auth-box__logo" >
            </header>

          <form  @submit.prevent="onCreateAccount">
            <label class="form-label"  for="">Zapomniałeś hasła? Podaj adres mail dla którego mamy zresetować hasło</label>
       
          <div class="form-field">
                  <label class="form-label"  for="">Adres email</label>
                  <input v-model="email" type="email" name="email" id="">          
          </div>
   

            <button type="submit" class="btn btn--primary btn--full">Resetuj hasło</button>
          </form>
          <footer class="auth-box__footer">
              <nav class="auth-box__nav">
                  <router-link class="auth-box__nav__item" to="/logowanie">Zaloguj się</router-link>
              </nav>
          </footer>
        </Box>
      </div>
    </div>
</template>

<script>

import {inject,ref} from 'vue';
import Topbar from '@/components/Topbar.vue';
import CustomChart from '@/components/CustomChart.vue';
import Box from '@/components/Box.vue';
import { useRouter, useRoute } from 'vue-router';
import hily_logo from '@/assets/hily_logo.svg';


export default {
  name: 'ResetPassView',

  components: {
    CustomChart,Topbar,Box
  },
  setup(props, {emit}) {
    const axios = inject('axios');
    let email = ref('');
    let password = ref('');
    let userName = ref('');
    let logo= ref(hily_logo);
    const router = useRouter();
    if(localStorage.getItem('token')){
      router.push('/');
    }
    async function  onCreateAccount() {
      emit('removeAlerts');
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.value.match(validRegex)){
          await axios.post('/user-account/send-password-reset-code', { email: this.email})
        .then(response => {

          emit('alert',{
                  type: 'success',
                  title: 'Twoje konto zostało znalezione',
                  data: ['Sprawdź skrzynkę email. Znajdziesz tam kod potwierdzający Twoją tożsamość.'],
                  visible: true,
              } );
          router.push({ path: '/odzyskaj-haslo-kod', replace: true })

          const resetPassToken = response.data.data.confirmationToken;
            localStorage.setItem('resetPassToken', resetPassToken);
        }).catch(err => {
          emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z resetem hasła',
                  data: [err.response.data.error.message],
                  visible: true,
              } );
        })
        }else{
          emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z resetem hasła',
                  data: ['To nie jest adres email'],
                  visible: true,
              } );
        }
 
    };
    return {onCreateAccount,userName,email,password,logo};
  },
}
</script>

