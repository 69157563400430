<template>
    <Topbar > 
      <div class="calendar-topbar">
        <div class="calendar-topbar__nav">
          <button @click="movePrev" class="calendar-topbar__nav__button">
            <span v-html="'<'"></span>
          </button>
          <button v-show="new Date().toDateString() !== new Date(range.end).toDateString()" @click="moveNext" class="calendar-topbar__nav__button">
            <span v-html="'>'"></span>
          </button>
        </div>
        <div class="calendar-topbar__date-range">
          {{ dateTransformer(range.start) }} - {{ dateTransformer(range.end) }}
        </div>
        <div class="date-selector-wrapper">

            <button @click.prevent="toggleOpenDateSelector" class="btn btn--secondary date-selector-wrapper__button" :class="{'btn--active': dateSelectorOpen}">Zmień datę</button>

            <div class="date-selector-wrapper__field" v-show="dateSelectorOpen">
              <VDatePicker ref="calendarPicker" @daymouseenter="handleInput"  trim-weeks  :isRange="true" title-position="left" borderless  v-model.range="range"/>
            </div>
        </div>
      </div>
    
    </Topbar>
    <div class="app__content__wrapper">

  
  
     <Calendar  v-if="eventData.length"
      :dayData="eventData"  
      :anyEvents="anyEvents"
      :length="eventData.length"
      @deleteEvent="deleteEvent"
      :visitMode="true"
      />
    </div>
   

</template>

<script>
import { format, subYears,subDays,addDays,differenceInCalendarDays,set,add  } from 'date-fns';
import pl from 'date-fns/locale/pl';
import Calendar from '@/components/calendar/Calendar.vue';
import Topbar from '@/components/Topbar.vue';
import {ref, watch, onMounted,inject} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useFooterGap} from '@/hooks/useFooterGap';
export default {
  name: 'ViewersCallendarView',
  components: {
    Topbar,Calendar
  },
  props: {
    formFooter: Boolean,
  },
  setup(props,{emit}) {
    useFooterGap(props.formFooter,emit);
      let dateSelectorOpen = ref(false);
      const calendarPicker = ref(null);
      const route = useRoute();
 
      const isSetDate = typeof route.query.date != 'undefined';
      const axios = inject('axios');
      let anyEvents = ref(false);
      const range = ref({
          start: isSetDate ? new Date(route.query.date) : new Date().setDate(new Date().getDate() -6),
          end: isSetDate ? new Date(route.query.date).setDate(new Date(route.query.date).getDate() + 6) : new Date(),
      });

    
      const typeNames = { 
          MedicalTestResult: {
            id: 'medical-test',
            name: '+Wynik IgG'
          },
          Appointment: {
            id: 'visit',
            name: 'Wizyta'
          },
          DrugIntake: {
            id: 'medication',
            name: '+Podanie IgG'
          }
        
        
        };
      const rules = ref({
      
      });
      let eventData = ref( [
        {
          name: 'Poniedziałek',
          date: '15.01',

          events: [
            {
              id: 12,
              date: '15.01.2023',
              type: 'medical-test',
              typeName: 'Badanie',
              desc: 'Dermatolog',
              timeStart: '11:00',
              timeEnd: '12:30',
              location: 'Nowogrodzka 67, <br /> 02-122 Warszawa',
              medicalTestData:[
                {
                  name: 'Cukier',
                  value: 123,
                  unit: 'mg/dl'
                },
                {
                  name: 'Bunkier',
                  value: 2,
                  unit: 'mg/dl'
                },
                {
                  name: 'Stężenie kaczuszki',
                  value: 100,
                  unit: '%'
                }
               
              ],
            }
          ]
        },
        {
          name: 'Wtorek',
          date: '16.01',

          events: [
            {
              id: 22,
              date: '16.01.2023',
              type: 'visit',
              typeName: 'Wizyta',
              desc: 'Diabetolog, cukrzyca',
              timeStart: '13:00',
              timeEnd: '13:30',
              doctorName: 'Dr Jan Kot',
              location: 'Nowogrodzka 67, <br /> 02-122 Warszawa',
              locationPhone: '534 434 232'
              
            }
          ]
        },
        {
          name: 'Środa',
          date: '17.01',

          events: [
            {
              id: 221,
              date: '17.01.2023',
              type: 'medical-test',
              typeName: 'Badanie',
              desc: 'Morfologia krwi',
              timeStart: '9:00',
              timeEnd: '9:15',
              location: 'Dom',
              medicalTestData:[
                {
                  name: 'Cukier',
                  value: 123,
                  unit: 'mg/dl'
                },
                {
                  name: 'Bunkier',
                  value: 2,
                  unit: 'mg/dl'
                },
                {
                  name: 'Stężenie kaczuszki',
                  value: 100,
                  unit: '%'
                }
               
              ],
            }
          ]
        }

      ]);
      function convertStringHourToArray(strHour){
        return strHour.split(':').map(Number);
      }
      async function getEventData (){
        await axios.get(`/viewers/${route.params.uId}/date-events`,{params:{start: format(range.value.start, 'yyyy-MM-dd'), end:format(range.value.end, 'yyyy-MM-dd')}})
        .then(response => {
          
          let len = differenceInCalendarDays( range.value.end,range.value.start);
          anyEvents.value= false;
          let finalData = [];
         
          for(let i = 0 ; i <= len; i++){
            let date = addDays(range.value.start, i);


              var data = {
                name: format(new Date(date), 'EEEE', {locale: pl}),
                date: format(new Date(date), 'dd.MM'),
                events: []
              }

            let dataIndex = response.data.data.findIndex(day =>{
            
             return differenceInCalendarDays(new Date(day.date), date) == 0
            });
        
            
          
            if(dataIndex != -1){
          
              anyEvents.value= true;
              response.data.data[dataIndex].dateEvents.forEach(event =>{
                      let defaultEndTime = convertStringHourToArray(event.start);
            
                      let tempData = {
                        id: event.id,
                        date: format(new Date( response.data.data[dataIndex].date), 'dd.MM.Y'),
                        type:  typeNames[event.type].id,
                        typeName: typeNames[event.type].name,
                        desc: event.title,
                        timeStart: event.start,
                        description: event.description,
                       // timeEnd: event.type == "Appointment" ? event.end : format(add(set(new Date(),{hours: defaultEndTime[0], minutes: defaultEndTime[1]}), {minutes: 30}), 'HH:mm'),
                        timeEnd:  event.end ,
                        location: event.place,
                      };
                   
                      if(event.type == "MedicalTestResult"){
                        tempData.medicalTestData = [];
                     
                        event.measurements.forEach(test => {
                          // let index = event.schema.metrics.findIndex(schema => schema.id == test.metricId);
                     
                          // if(index != -1){
                            tempData.medicalTestData.push({
                              name: test.name,
                              value: test.baseValue,
                              unit: test.baseUnit
                            });
                          // }

                        });

                      }

                      if(event.type == "DrugIntake"){
                        
                        
                        tempData.dosages = event.dosages;
                    

                      }

                    
                 
                      data.events.push(tempData);
                   
                });
            }
       
            finalData.push(data);
       
          }
          
   
 
          eventData.value = finalData;

          
        })
        .catch(err => {
       
        });
      }
      onMounted(async () =>{
        await getEventData();
        
      });
    

     
      
      const handleInput = (evt) => {
       
      // range.value = {
      //   start: range.value.start,
      //   end: new Date().setDate(range.value.start.getDate() + 7),
      // };
    };
    function toggleOpenDateSelector(){
      dateSelectorOpen.value = !dateSelectorOpen.value;
    }

    function dateTransformer(data){
      let date = new Date(data);
      let day = date.getDate() < 10 ? '0'+ date.getDate() :  date.getDate();
      let month = date.getMonth() + 1 < 10 ? '0'+ (date.getMonth() + 1) :  date.getMonth() + 1;
      let year = date.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
      return `${day}.${month}.${year}`;
    }

    function movePrev(){
    
     range.value = {
      start: subDays (new Date(range.value.start), 7),
        end: subDays (new Date(range.value.end), 7), 
      };

    }
     
    function moveNext(){
   
     range.value = {
        start: differenceInCalendarDays(new Date(),new Date(range.value.end)) < 7 ? subDays (new Date(), 7) :  addDays (new Date(range.value.start), 7) ,
        end: differenceInCalendarDays(new Date(),new Date(range.value.end)) < 7 ? new Date() : addDays  (new Date(range.value.end), 7), 
      };
 
    }

    watch( range, async newVal => {

      // if(differenceInCalendarDays( newVal.end,newVal.start) > 7 ){
      //   range.value = {
      //     start: new Date(newVal.start) ,
      //     end: addDays (new Date(newVal.start), 7)
      //   };
      // }
      // calendarPicker.value.move(new Date(newVal.end));

      await getEventData();
    })

    async function deleteEvent(data){
      let dataEndpoint ='';
      let dataEndpointSuccessText ='';
      let dataEndpointErrorText ='';
      if(data.type == 'medical-test'){
        dataEndpoint = 'medical-test-results';
        dataEndpointSuccessText ='Badanie IgG zostało usunięte z systemu!';
        dataEndpointErrorText ='Wystąpił problem z usunięciem badania IgG';
      }
      if(data.type == 'visit'){
        dataEndpoint = 'appointments';
        dataEndpointSuccessText ='Wizyta została usunięta z systemu!';
        dataEndpointErrorText ='Wystąpił problem z usunięciem wizyty';
      }

      if(data.type == 'medication'){
        dataEndpoint = 'drug-intakes';
        dataEndpointSuccessText ='Podanie IgG zostało usunięte z systemu!';
        dataEndpointErrorText ='Wystąpił problem z usunięciem podania IgG';
      }

      await axios.delete(`/${dataEndpoint}/${data.id}`)
        .then(async response => {
          emit('alert',{
                  type: 'success',
                  title: dataEndpointSuccessText,
                  visible: true,
                  autoClose: true,
                  data: []
                } );
        
          await getEventData();
                

        })
        .catch(err => {
        
            emit('alert',{
                  type: 'error',
                  title: dataEndpointErrorText,
                  data: [err.response.data.error.message],
                  visible: true,
              } );
        });
    
    }
    return {deleteEvent,anyEvents,eventData,dateSelectorOpen,toggleOpenDateSelector,range,rules,handleInput,dateTransformer,calendarPicker, movePrev,moveNext};
  },
}
</script>


<style lang="scss" scoped>
.calendar-topbar{
  display: flex;
  align-items: center;

  @media(max-width: $md){
    flex-wrap: wrap;
    .btn{
      margin-top: 5px;
 
    }
  }

  &__date-range{
    margin: 0px 20px;
    font-size: 20px;
    font-weight: 700;
    color: #031b61;

    @media(max-width: $md){
      font-size: 14px;
      margin: 0px 10px;
    }
  }
  &__nav{
    &__button{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid $border_color;
      background: white;
      transition: background .3s ease-in-out;
      &:hover{
        background: darken(#fff, 5%);

      }

      &:first-child{
        margin-right: 5px;
      }
    }
  
  }
}

</style>