<template>
  <div >
  <div class="auth-wrap">
    <Box class="auth-box"> 
      <header class="auth-box__header">
        <h1 class="auth-box__title">Logowanie</h1>
        <img :src="logo" alt="Hily logo" class="auth-box__logo" >
      </header>

    <form action="" @submit.prevent="onSignIn" autocomplete="off">

      <div class="form-field">
            <label class="form-label"  for="">Adres email</label>
            <input v-model="email" type="email" name="email" id="">
     </div>
     <div class="form-field">
            <label class="form-label"  for="">Haslo</label>
            <input v-model="password" type="password" name="password" id="">
    </div>

    

    <button type="submit" class="btn btn--primary btn--full">Zaloguj</button>
   </form>
   <footer class="auth-box__footer">
      <nav class="auth-box__nav">
          <router-link class="auth-box__nav__item" to="/rejestracja">Zarejestruj</router-link>
          <router-link to="/odzyskaj-haslo" class="auth-box__nav__item">Niepamiętam hasła</router-link>
      </nav>
   </footer>
  </Box>
  </div>

</div>
   

</template>

<script>

import { useRouter, useRoute } from 'vue-router';
import {inject,ref,computed} from 'vue';
import Topbar from '@/components/Topbar.vue';
import CustomChart from '@/components/CustomChart.vue';
import Box from '@/components/Box.vue';
import hily_logo from '@/assets/hily_logo.svg';



export default {
  name: 'LoginView',

  components: {
    CustomChart,Topbar,Box
  },
   emit: ['alert', 'removeAlerts'],
  setup(props, {emit}) {
    let email = ref('');
    let password = ref('');
    const axios = inject('axios');
    const router = useRouter();
    const logo = ref(hily_logo);
    if(localStorage.getItem('token')){
      router.push('/');
    }
    async function onSignIn() {
      emit('removeAlerts');


      axios.post('/auth/signin', { email: this.email, password: this.password })
        .then(response => {
          const { data }  = response.data;
          const token = data.token;

          // this.store.clearStore();
          localStorage.setItem('token', token);

       
          router.push({ path: '/', replace: true });
          localStorage.removeItem('registerToken');
          localStorage.removeItem('resetPassToken');
          localStorage.removeItem('isAdmin');
          // this.store.addDirty('user');
          // this.store.addDirty('games');
     
        }).catch(err => {
    
          if (err.response.status == 401) {
            emit('alert',{
                  type: 'error',
                  title: 'Wystąpił błąd',
                  data: [err.response.data.error.message],
                  visible: true,
              } );
            if(err.response.data.error.code == 'email_not_verified'){
                const token = err.response.data.data.confirmationToken;
           
                 localStorage.setItem('registerToken',token);
              emit('alert',{
                  type: 'error',
                  title: 'Potwierdzenie tożsamości',
                  data: ['Twoje konto jest już zarejestrowane w Hily, ale wymaga jeszcze potwierdzenia tożsamości   '],
                  visible: true,
              } );
           
              router.push({ path: '/autoryzacja', replace: true })
            }
          }else{
              emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z logowaniem',
                  data: [err.response.data.error.message],
                  visible: true,
              } );
           }

        
        });

    };
    return {onSignIn, email,password,logo};
  },
}
</script>

