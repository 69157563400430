<template>
 <div ref="sidebox" class="sidebox" :class="{'sidebox--is-open': isOpen}">
  <div class="sidebox__wrapper">
    <div>
      <button @click="onExit" class="sidebox__exit">x</button>
     
      <div class="sidebox__content-wrapper">
        <h3 v-if="title" class="sidebox__title">{{ title }}</h3>
        <SideboxContentCallendarTypeVisit  :visitMode="visitMode" :data="data" v-if="type == 'visit'" @deleteEvent=" id => deleteEvent(id, 'visit')"/>
        <SideboxContentCallendarTypeMedicalTest  :visitMode="visitMode" :data="data" @deleteEvent=" id => deleteEvent(id, 'medical-test')" v-if="type == 'medical-test'"/>
          <SideboxContentCallendarTypeMedication :visitMode="visitMode"  :data="data" v-if="type == 'medication'" @deleteEvent=" id => deleteEvent(id, 'medication')"/>
      </div>
    </div>
    
  </div>
   
 </div>
</template>

<script>
import {ref,watch} from 'vue';
import SideboxContentCallendarTypeMedicalTest from './SideboxContentCallendarTypeMedicalTest.vue';
import SideboxContentCallendarTypeVisit from './SideboxContentCallendarTypeVisit.vue';
import SideboxContentCallendarTypeMedication from './SideboxContentCallendarTypeMedication.vue';
import SideboxContentChart from './SideboxContentChart.vue';
export default {
  name: 'sidebox',
  props: {
    // title: String,
    type: String,
    data: Object,

    visitMode:{
      type: Boolean,
      default: false
    },
    open:{
      type: Boolean,
      default: false
    }
  },
  components:{
    SideboxContentCallendarTypeMedicalTest, SideboxContentCallendarTypeVisit,SideboxContentChart,SideboxContentCallendarTypeMedication
  },

  setup(props,{emit}){
   let isOpen = ref(props.open);
    const sidebox = ref(null);
   watch(()=> props.open, (newVal) => {
      isOpen.value = newVal; 
    });

  

    function scrollTop(){
        if (sidebox.value) {
          // window.scrollTo(0, sidebox.value.offsetTop)
        }
    }
   function onExit(){
    isOpen.value = false; 
 
     
    emit('onExit');
  }
  function deleteEvent(id,type){
    emit('deleteEvent', {id, type});

  }
    return {isOpen,onExit,sidebox,scrollTop,deleteEvent};
  }
}
</script>


<style lang="scss" >
  .sidebox{
    padding: 15px 0px;
    box-shadow: -5px 6px 44px 0px rgba(0, 0, 0, 0.06);
    border-left: 1px solid $border_color;;
    position: fixed;
    background-color: white;
    max-width: 350px;
    width: 350px;
    z-index: 9999999;
    top: 00px;
    right: 0;
    bottom: 0;
    height: calc(100% );
    transition: transform .3s ease-in-out;
    transform: translateX(100%);

    @media(max-width: $sm){
      width: 100%;
    }
    &--is-open{
      transform: translateX(0%);
    }
    &__exit{
      margin-left: auto;
      display: flex;
      background-color: transparent;
      border-width: 0;;
      color: $text_color;
      margin-bottom: 20px;
      font-size: 24px;
      margin-right: 30px;;
    }
   

    &__content-wrapper{
      height: calc(100vh - 150px);
      overflow-y: auto;
      padding-left: 30px;
      padding-right: 30px;

     
    }

  
  }

  .sidebox-content{
    &__title{
      color: $text_color;
      font-size: 18px;
      font-weight: 500;
      margin-top: 0px;
      margin-bottom: 10px;;
    }

    &__header{
      display: flex;
      align-items: space-between;
      flex-direction: column-reverse;
      // .sidebox-content{
      //   &__title{
      //     margin-right: 20px;
      //   }
      // }

      &__buttons{
        margin-bottom: 20px;
        margin-left: auto;
        > *{
          margin-left: 15px;
        }
      }
    }
    &__main-info{
        background-color: #f8fafe;
        padding: 30px ;
        margin: 30px -30px;
      
    }
    &__desc{
      font-size: 14px;
    }
    &__additional-info, &__main-info{
      &__title{
        font-size: 18px;;
      }

      &--desc{
      
        margin-top: 50px;
     
      }
    }
  }
</style>