<template>
 <div  class="sidebox-content sidebox-content--type-visit" >
    <header class="sidebox-content__header">
      <h3 class="sidebox-content__title">
        +Podanie IgG, <br>
        <b>{{ data.desc }}</b>
      </h3>
      <div class="sidebox-content__header__buttons" v-if="!visitMode">
        <router-link  class="btn btn--secondary" :to="`/podanie-leku/edytuj/${data.id}`">Edytuj</router-link>
        <button class="btn btn--primary" @click="deleteItem(data.id)" >Usuń</button>
      </div>
    
    </header>
    
    <div class="sidebox-content__main-info" >
      <ul class="info-icon-list">
        <li class="info-icon-list__item">
          <div class="info-icon-list__item__icon-wrapper">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="50px" height="54.5px" viewBox="150 45.5 500 504.5" enable-background="new 150 45.5 500 504.5" xml:space="preserve">
              <g id="icons">
                <g id="calendar">
                  <path fill="#9CDABE" d="M550,100c0-27.5-22.5-50-50-50s-50,22.5-50,50H350c0-27.5-22.5-50-50-50s-50,22.5-50,50
                    c-55,0-100,45-100,100v250c0,55,45,100,100,100h300c55,0,100-45,100-100V200C650,145,605,100,550,100z M600,475c0,15-10,25-25,25
                    H225c-15,0-25-10-25-25V275c0-15,10-25,25-25h350c15,0,25,10,25,25V475z"/>
                  <path fill="#9CDABE" d="M425,300h-50c-27.5,0-50,22.5-50,50v50c0,27.5,22.5,50,50,50h50c27.5,0,50-22.5,50-50v-50
                    C475,322.5,452.5,300,425,300z"/>
                </g>
              </g>
              </svg>

          </div>

          {{ data.date }}
        </li>

        <li class="info-icon-list__item">
          <div class="info-icon-list__item__icon-wrapper">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="50px" height="50px" viewBox="150 50 500 500" enable-background="new 150 50 500 500" xml:space="preserve">
            <path fill="#9CDABE" d="M400,50c-138.071,0-250,111.929-250,250c0,138.07,111.929,250,250,250s250-111.93,250-250
              C650,161.929,538.071,50,400,50z M400,500c-110.457,0-200-89.544-200-200c0-110.457,89.543-200,200-200s200,89.543,200,200
              C600,410.456,510.457,500,400,500z M425,150h-50v175h150v-50H425V150z"/>
            </svg>


          </div>

          {{ data.timeStart }} - {{ data.timeEnd }}
        </li>

        <li class="info-icon-list__item"  v-show="false">
          <div class="info-icon-list__item__icon-wrapper">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="41px" height="42.95px" viewBox="195.5 95.5 410 429.5" enable-background="new 195.5 95.5 410 429.5"
              xml:space="preserve">
            <circle fill="#9CDABE" cx="400" cy="200" r="100"/>
            <path fill="#9CDABE" d="M600,475v25c0,13.807-11.192,25-25,25H225c-13.807,0-25-11.193-25-25v-25c0-82.843,67.157-150,150-150h100
              C532.843,325,600,392.157,600,475z"/>
            </svg>


          </div>

          {{ data.doctorName }} 
        </li>
      </ul>
    </div>
    <div class="sidebox-content__additional-info">
        <h3 class="sidebox-content__additional-info__title">Przyjęte leki</h3>

        <ul class="medical-test-list" v-if="data.dosages.length">
          <li class="medical-test-list__item" v-for="(test, index) in data.dosages" :key="index"> {{ test.drugName }}: <b>{{ test.amount}} {{ test.unitName }}</b></li>
        </ul>
      </div>

      <div class="sidebox-content__additional-info sidebox-content__additional-info--desc" v-if="data.description">
        <h3 class="sidebox-content__additional-info__title">Opis</h3>

        <p class="sidebox-content__desc">
          {{ data.description }}
        </p>
      </div>
 
 </div>
</template>

<script>
import {ref,watch} from 'vue';
export default {
  name: 'SideboxContentCallendarTypeMedication',
  props: {
    data: Object,
    visitMode:{
      type: Boolean,
      default: false
    },
  },

  setup(props,{emit}){
    function deleteItem(id){
      emit('deleteEvent',id);
    }
    return {deleteItem};
    }
  }
</script>


<style lang="scss" scoped>

</style>