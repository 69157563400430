<template>
  <nav class="menu">
    <template v-if="!isViewer">
      <router-link active-class="menu__item--active" class="menu__item" to="/">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          width="608px" height="503.5px" viewBox="95.5 46.5 608 503.5" enable-background="new 95.5 46.5 608 503.5" xml:space="preserve">
            <g id="icons">
              <path id="home" fill="#8B8B8B" d="M690,257.5L430,60c-17.5-12.5-42.5-12.5-62.5,0L110,257.5c-20,15-10,42.5,15,42.5h25v152.5
                c0,52.5,45,97.5,100,97.5h50c15,0,25-10,25-25V402.5c0-27.5,22.5-52.5,50-52.5h50c27.5,0,50,25,50,52.5V525c0,12.5,10,25,25,25h50
                c55,0,100-45,100-97.5V300h25C697.5,300,707.5,272.5,690,257.5z"/>
            </g>
          </svg>
        </span>
        Mój Dzienniczek
      </router-link>

      <router-link active-class="menu__item--active" class="menu__item" to="/badania">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="34px" height="34px" viewBox="3 2.999 14 14.002" enable-background="new 3 2.999 14 14.002" xml:space="preserve">
          <path fill="#8B8B8B" d="M8.5,2.999c-0.829,0-1.5,0.671-1.5,1.5V7H4.5C3.671,7,3,7.671,3,8.5v3C3,12.328,3.671,13,4.5,13H7v2.501
            c0,0.829,0.671,1.5,1.5,1.5h3c0.828,0,1.5-0.671,1.5-1.5V13h2.5c0.828,0,1.5-0.672,1.5-1.5v-3C17,7.671,16.328,7,15.5,7H13V4.499
            c0-0.829-0.672-1.5-1.5-1.5H8.5z"/>
          </svg>

        </span>
        +Wynik IgG
      </router-link>

      <router-link active-class="menu__item--active" class="menu__item" to="/podanie-leku">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="34px" height="34px" viewBox="3 2.999 14 14.002" enable-background="new 3 2.999 14 14.002" xml:space="preserve">
          <path fill="#8B8B8B" d="M8.5,2.999c-0.829,0-1.5,0.671-1.5,1.5V7H4.5C3.671,7,3,7.671,3,8.5v3C3,12.328,3.671,13,4.5,13H7v2.501
            c0,0.829,0.671,1.5,1.5,1.5h3c0.828,0,1.5-0.671,1.5-1.5V13h2.5c0.828,0,1.5-0.672,1.5-1.5v-3C17,7.671,16.328,7,15.5,7H13V4.499
            c0-0.829-0.672-1.5-1.5-1.5H8.5z"/>
          </svg>

        </span>
        +Podanie IgG
      </router-link>

      <router-link active-class="menu__item--active" class="menu__item" to="/admin" v-show="isAdmin">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="410px" height="429.5px" viewBox="195.5 95.5 410 429.5" enable-background="new 195.5 95.5 410 429.5"
            xml:space="preserve">
          <circle fill="#8B8B8B" cx="400" cy="200" r="100"/>
          <path fill="#8B8B8B" d="M600,475v25c0,13.807-11.192,25-25,25H225c-13.807,0-25-11.193-25-25v-25c0-82.843,67.157-150,150-150h100
            C532.843,325,600,392.157,600,475z"/>
          </svg>
        </span>
        Admin
      </router-link>




      <router-link active-class="menu__item--active" class="menu__item" to="/wizyty">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="552.5px" height="379.5px" viewBox="125 120.5 552.5 379.5" enable-background="new 125 120.5 552.5 379.5"
            xml:space="preserve">
            <g>
              <g>
                <path fill="#8B8B8B" d="M512.5,350c-37.5,0-67.5,9.999-90,22.499c35,30.001,50,65.001,52.5,67.501l2.5,5v55h200v-50
                  C675,450,635,350,512.5,350z"/>
              </g>
              <g>
                <circle fill="#8B8B8B" cx="512.5" cy="212.5" r="87.5"/>
              </g>
            </g>
            <g>
              <path fill="#8B8B8B" d="M200,212.5c0-47.5,40-87.5,87.5-87.5s87.5,40,87.5,87.5S335,300,287.5,300S200,260,200,212.5z M287.5,350
                C165,350,125,450,125,450v50h325v-50C450,450,410,350,287.5,350z"/>
            </g>
          </svg>
        </span>
        Wizyty lekarskie
      </router-link>

      <router-link active-class="menu__item--active" class="menu__item" to="/kalendarz">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="500px" height="504.5px" viewBox="150 45.5 500 504.5" enable-background="new 150 45.5 500 504.5" xml:space="preserve">
            <g id="icons">
              <g id="calendar">
                <path fill="#8B8B8B" d="M550,100c0-27.5-22.5-50-50-50s-50,22.5-50,50H350c0-27.5-22.5-50-50-50s-50,22.5-50,50
                  c-55,0-100,45-100,100v250c0,55,45,100,100,100h300c55,0,100-45,100-100V200C650,145,605,100,550,100z M600,475c0,15-10,25-25,25
                  H225c-15,0-25-10-25-25V275c0-15,10-25,25-25h350c15,0,25,10,25,25V475z"/>
                <path fill="#8B8B8B" d="M425,300h-50c-27.5,0-50,22.5-50,50v50c0,27.5,22.5,50,50,50h50c27.5,0,50-22.5,50-50v-50
                  C475,322.5,452.5,300,425,300z"/>
              </g>
            </g>
          </svg>
        </span>
        Kalendarz
      </router-link>


      <router-link active-class="menu__item--active" class="menu__item" to="/eksport">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="451.5px" height="550px" viewBox="185.5 0 451.5 550" enable-background="new 185.5 0 451.5 550" xml:space="preserve">
            <g id="icons">
              <g id="save">
                <path fill="#8B8B8B" d="M392,415c10,12.5,30,12.5,40,0l150-157.5c12.5-12.5,0-32.5-20-32.5H462c0,0,5-115,0-175
                  c-2.5-27.5-22.5-50-50-50s-47.5,22.5-50,50c-5,57.5,0,175,0,175H262c-20,0-32.5,20-20,35L392,415z"/>
                <path fill="#8B8B8B" d="M587,475H237c-27.5,0-50,22.499-50,50l0,0c0,15,10,25,25,25h400c15,0,25-10,25-25l0,0
                  C637,497.499,614.5,475,587,475z"/>
              </g>
            </g>
          </svg>

        </span>
        Eksport
      </router-link>
  
      <router-link active-class="menu__item--active" class="menu__item" to="/moje-konto">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="410px" height="429.5px" viewBox="195.5 95.5 410 429.5" enable-background="new 195.5 95.5 410 429.5"
            xml:space="preserve">
          <circle fill="#8B8B8B" cx="400" cy="200" r="100"/>
          <path fill="#8B8B8B" d="M600,475v25c0,13.807-11.192,25-25,25H225c-13.807,0-25-11.193-25-25v-25c0-82.843,67.157-150,150-150h100
            C532.843,325,600,392.157,600,475z"/>
          </svg>
        </span>
        Moje konto
      </router-link>
      <button href="" @click.prevent="logOut" class="menu__item">Wyloguj</button>
    </template>

    <template v-if="isViewer">
      <router-link active-class="menu__item--active" class="menu__item" :to="`${viewerLink}`">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          width="608px" height="503.5px" viewBox="95.5 46.5 608 503.5" enable-background="new 95.5 46.5 608 503.5" xml:space="preserve">
            <g id="icons">
              <path id="home" fill="#8B8B8B" d="M690,257.5L430,60c-17.5-12.5-42.5-12.5-62.5,0L110,257.5c-20,15-10,42.5,15,42.5h25v152.5
                c0,52.5,45,97.5,100,97.5h50c15,0,25-10,25-25V402.5c0-27.5,22.5-52.5,50-52.5h50c27.5,0,50,25,50,52.5V525c0,12.5,10,25,25,25h50
                c55,0,100-45,100-97.5V300h25C697.5,300,707.5,272.5,690,257.5z"/>
            </g>
          </svg>
        </span>
        Dzienniczek
      </router-link>

      <router-link active-class="menu__item--active" class="menu__item" :to="`${viewerLink}/badania`">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="34px" height="34px" viewBox="3 2.999 14 14.002" enable-background="new 3 2.999 14 14.002" xml:space="preserve">
          <path fill="#8B8B8B" d="M8.5,2.999c-0.829,0-1.5,0.671-1.5,1.5V7H4.5C3.671,7,3,7.671,3,8.5v3C3,12.328,3.671,13,4.5,13H7v2.501
            c0,0.829,0.671,1.5,1.5,1.5h3c0.828,0,1.5-0.671,1.5-1.5V13h2.5c0.828,0,1.5-0.672,1.5-1.5v-3C17,7.671,16.328,7,15.5,7H13V4.499
            c0-0.829-0.672-1.5-1.5-1.5H8.5z"/>
          </svg>

        </span>
        +Wynik IgG
      </router-link>

      <router-link active-class="menu__item--active" class="menu__item" :to="`${viewerLink}/podanie-leku`">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="34px" height="34px" viewBox="3 2.999 14 14.002" enable-background="new 3 2.999 14 14.002" xml:space="preserve">
          <path fill="#8B8B8B" d="M8.5,2.999c-0.829,0-1.5,0.671-1.5,1.5V7H4.5C3.671,7,3,7.671,3,8.5v3C3,12.328,3.671,13,4.5,13H7v2.501
            c0,0.829,0.671,1.5,1.5,1.5h3c0.828,0,1.5-0.671,1.5-1.5V13h2.5c0.828,0,1.5-0.672,1.5-1.5v-3C17,7.671,16.328,7,15.5,7H13V4.499
            c0-0.829-0.672-1.5-1.5-1.5H8.5z"/>
          </svg>

        </span>
        +Podanie IgG
      </router-link>

      <router-link active-class="menu__item--active" class="menu__item" :to="`${viewerLink}/wizyty`">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="552.5px" height="379.5px" viewBox="125 120.5 552.5 379.5" enable-background="new 125 120.5 552.5 379.5"
            xml:space="preserve">
            <g>
              <g>
                <path fill="#8B8B8B" d="M512.5,350c-37.5,0-67.5,9.999-90,22.499c35,30.001,50,65.001,52.5,67.501l2.5,5v55h200v-50
                  C675,450,635,350,512.5,350z"/>
              </g>
              <g>
                <circle fill="#8B8B8B" cx="512.5" cy="212.5" r="87.5"/>
              </g>
            </g>
            <g>
              <path fill="#8B8B8B" d="M200,212.5c0-47.5,40-87.5,87.5-87.5s87.5,40,87.5,87.5S335,300,287.5,300S200,260,200,212.5z M287.5,350
                C165,350,125,450,125,450v50h325v-50C450,450,410,350,287.5,350z"/>
            </g>
          </svg>
        </span>
        Wizyty lekarskie
      </router-link>

      <router-link active-class="menu__item--active" class="menu__item" :to="`${viewerLink}/kalendarz`">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="500px" height="504.5px" viewBox="150 45.5 500 504.5" enable-background="new 150 45.5 500 504.5" xml:space="preserve">
            <g id="icons">
              <g id="calendar">
                <path fill="#8B8B8B" d="M550,100c0-27.5-22.5-50-50-50s-50,22.5-50,50H350c0-27.5-22.5-50-50-50s-50,22.5-50,50
                  c-55,0-100,45-100,100v250c0,55,45,100,100,100h300c55,0,100-45,100-100V200C650,145,605,100,550,100z M600,475c0,15-10,25-25,25
                  H225c-15,0-25-10-25-25V275c0-15,10-25,25-25h350c15,0,25,10,25,25V475z"/>
                <path fill="#8B8B8B" d="M425,300h-50c-27.5,0-50,22.5-50,50v50c0,27.5,22.5,50,50,50h50c27.5,0,50-22.5,50-50v-50
                  C475,322.5,452.5,300,425,300z"/>
              </g>
            </g>
          </svg>
        </span>
        Kalendarz
      </router-link>
      <router-link active-class="menu__item--active" class="menu__item" :to="`${viewerLink}/dane-pacjenta`">
        <span class="menu__item__icon-wrapper">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="410px" height="429.5px" viewBox="195.5 95.5 410 429.5" enable-background="new 195.5 95.5 410 429.5"
            xml:space="preserve">
          <circle fill="#8B8B8B" cx="400" cy="200" r="100"/>
          <path fill="#8B8B8B" d="M600,475v25c0,13.807-11.192,25-25,25H225c-13.807,0-25-11.193-25-25v-25c0-82.843,67.157-150,150-150h100
            C532.843,325,600,392.157,600,475z"/>
          </svg>
        </span>
        Dane pacjenta
      </router-link>
    </template>

  </nav>
</template>


<script>
import{ inject, onMounted,ref,computed} from 'vue';
import { useRouter,onBeforeRouteUpdate,useRoute } from 'vue-router';
export default { 
  name: 'Menu',
  props: {
    msg: String,
    
  },

  setup(props){
    const router = useRouter();
    const axios = inject('axios');
    const route = useRoute();
    const currentPath = computed(() => route.path);

    const isAdmin = ref(false);
    let isViewer = ref(false);
    let viewerLink = ref('');

    onMounted( async() => {
      await router.isReady();
      viewerLink.value = `/podglad/${route.params.uId}`;
      isViewer.value = currentPath.value.includes('podglad');
      if(!currentPath.value.includes('podglad')){
        await axios.get('/auth/me')
          .then( async response => {
            isAdmin.value = response.data.data.role == 'admin' ? true : false;
            localStorage.setItem('isAdmin',isAdmin.value );
          });
      }
    });   

    function logOut(){
      localStorage.removeItem('token');
      localStorage.removeItem('isAdmin');
      router.push('/logowanie')
    }
    return{
      logOut,isAdmin,isViewer,viewerLink
    }
  }

}
</script>


<style lang="scss" scoped>
$odd_bg: #f7f9fe;
$even_bg: #fff;

.menu{
  display: flex;
    flex-direction: column;
  &__item{
    display: flex;
    text-decoration: none;
    color: #393939;
    font-size: 15px;
    font-weight: 500;
    padding: 22px 18px 22px 30px;
    $menu__item: &;
    border-width: 0;
      @media(max-width: 768px){
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 11px;
        padding: 15px;
      }
    &:nth-child(odd){
      background-color: $odd_bg;
    }
    &:nth-child(even){
      background-color: $even_bg;
    }

    &:hover, &--active{
      background-color: #fffaf9 !important;
      color: #f3aca0;
      #{$menu__item}{
       &__icon-wrapper{
          *{
          fill: #f3aca0;
        }
       }
      }
    }

    &__icon-wrapper{
      display: inline-flex;
      width: 20px;
      height: auto;
      margin-right: 15px;
      @media(max-width: 768px){
        margin-right: 0;
        margin-bottom: 10px;
      }
      svg{
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
}
</style>