<template>
   <div class="form-footer" :style="{'height': `${height}px`}">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'FormFooter',
  props:{
    height: Number
  }



}
</script>


<style lang="scss" scoped>
  .form-footer{
     position: fixed;
      bottom: 0;
      right: 0;
      width: calc(100% - 220px);
      background-color: #f4f8ff;
    z-index: 99999;
      display: flex;
      align-items: center;
      justify-content: end;
      padding-right: 80px;

      @media(max-width: $md){
        width: calc(100% - 70px);
        padding-right: 20px;
      }

      @media(max-width: $xs){
        width: calc(100% );
        bottom: 50px;
      }
  }
</style>