
<template>
    <Topbar title="Edytuj badanie">
      <TSelect class="secondary-select" v-show="false" setupText="Wybierz typ badania" v-model="testTypeSelected" name="medical-tests"  :data="testType" />

    </Topbar>
 
    <div class="app__content__wrapper" >
      <form class="medical-test" @submit="onFormSubmit" >

        <div class="medical-test__left">
          <Box title="Uzupełnij dane">
            <div class="form-wrapper"  >
                <div class="form-field" :key="index" v-for="(item,index) in selectedData.data">
                  <div class="row" >
                    <div class="col-12">
                      <label class="form-label"  for="">{{item.name}}</label>
                    </div>
                    <div class="col-7">
                          <input step="any" v-model="testsFields[item.id]"  :type="item.type"  :name="item.id" :id="item.id">
                    </div>
                    <div class="col-5">
                          <TSelect  setupText="Jednostka" v-model="testsUnits[item.id]" name="medical-tests"  :data="item.units.map(item=> {return {value: item.id, text: item.name}})" />
                    </div>
                  </div>
                </div>
              </div>
          </Box>
        </div>
        

          <div class="medical-test__right">
            <Box  title="Określ kiedy">
              <div class="row">
                <div class="col-md-6">
                <VDatePicker ref="calendarPicker" :rules="rules" mode="dateTime" is24hr hide-time-header   trim-weeks  :isRange="false" title-position="left" borderless  v-model="date" :max-date="new Date()"/>
              
                <div>
                <label class="form-label">Czas trwania</label> 
                  <div class="form-field ">
                    <label class="form-label"  for="">Godziny</label>
                    <TSelect v-model="hour" setupText="Godziny" :data="[{value: 0, text: '0'},{value: 1, text: '1'},{value: 2, text: '2'},{value: 3, text: '3'},{value: 4, text: '4'}]"></TSelect>
                  </div>
                  <div class="form-field form-field">
                    <label class="form-label"  for="">Minuty</label>
                    <TSelect v-model="minutes" setupText="Minuty" :data="[{value: 0, text: '0'},{value: 15, text: '15'},{value: 30, text: '30'},{value: 45, text: '45'},]"></TSelect>
                  </div>
              </div>
             
              </div>
          
              <div class="col-md-6">
                <div class="form-field">
                  <label class="form-label"  for="">Opis Badania</label>
                  <textarea  v-model="desc"  cols="30" rows="10"></textarea>
                </div>

                <div class="form-field">
                  <label class="form-label"  for="">Miejsce badania</label>
                  <TRadios inline="true" v-model="place" :data="placeData"/>
                </div>
              </div>
             

              </div>
            

            </Box>
          </div>

          <FormFooter :height="footerHeight">
            <button type="submit" class="btn btn--primary btn--large">Zapisz</button>
          </FormFooter>
          
       
      </form>
     
     

    </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue';
import Alert from '@/components/Alert.vue';
import { format, addMinutes,differenceInMinutes,minutesToHours  } from 'date-fns';
import TSelect from '@/components/form/TSelect.vue';
import TCheckbox from '@/components/form/TCheckbox.vue';
import TCheckboxes from '@/components/form/TCheckboxes.vue';
import TRadios from '@/components/form/TRadios.vue';
import FormFooter from '@/components/FormFooter';
import Box from '@/components/Box.vue';
import {ref, inject, onMounted, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useFooterGap} from '@/hooks/useFooterGap';
export default {
  name: 'MedicalTestEditView',
  emit: ['alert'],
  components: {
    Topbar,TSelect,TCheckbox,Box,TCheckboxes,TRadios,FormFooter,Alert
  },
  props: {
    formFooter: Boolean,
  },
   setup(props, {emit}) {
    useFooterGap(props.formFooter,emit);
   
    const footerHeight = ref(100);
    const axios = inject('axios');
    let date = ref(new Date());
    const router = useRouter();
    const route = useRoute();
    let hour = ref(0);
    let minutes = ref(30);
    const rules = ref({
      minutes: [0,5,10,15,20,25,30,35,40,45,50,55],
  
    });
    let testsFields = ref({});
    let testsUnits = ref({});
    let place = ref('Dom');
    let desc = ref('');
    let testType = ref([]);
    let testData = ref([]);
    let testTypeSelected = ref(null);
    let selectedData = ref('');
       onMounted( async() => {
        await axios.get(`/medical-test-results/${route.params.id}`)
        .then(response => {
          let item = response.data.data;
            place.value = item.place;
           desc.value = item.description;
            testType.value.push({value: item.schema.id, text: item.schema.name});
            testData.value.push({id: item.schema.id, data: item.schema.metrics});
            testTypeSelected.value =  item.schema.id;
           
            let measurements = {};
            let selectedUnits = {}
            item.measurements.forEach(field =>{
              measurements[field.metricId] = field.value;
              selectedUnits[field.metricId] = field.unitId;
            })
            testsUnits.value = selectedUnits;
            console.log(testsUnits.value);
            testsFields.value = measurements;

            date.value = new Date(item.startDate);

            let tempMinutes = differenceInMinutes(new Date(item.endDate),new Date(item.startDate));
            let tempHours = minutesToHours(tempMinutes);
            tempMinutes =  tempMinutes > 60 ?   tempMinutes - (tempHours*60) : tempMinutes;
            
            
            minutes.value = tempMinutes;
            hour.value = tempHours;
        }).catch(() =>{
          router.push('/');
        });;
      });
    const placeData = [{text: 'Szpital', value: 'Szpital' }, {text: 'Dom',value: 'Dom'}, {text: 'Poradnia',value: 'Poradnia'}];
 
    watch(testTypeSelected, (newVal, oldVal) =>{
      console.log('Watch');
      selectedData.value = testData.value.filter(item=> newVal == item.id)[0];
      if(oldVal != null){
        var copyUnits = testsUnits.value;
      selectedData.value.data.forEach(item =>{
        let id = item.id;
        copyUnits[id] = item.units[0].id;
      });
      testsUnits.value = copyUnits;
      }
      
    });
 
 

    function  onChangePlace(event) {
  
        place.value = event;
    };
    function testu(){
        testTypeSelected.value = 2;
      }
      async function onFormSubmit(evt){
    evt.preventDefault();
    let error = false;
    let errorData = [];
    if(!date.value){
      errorData.push('Nie wybrano daty');
    }
    if(minutes.value == 0 && hour.value == 0){
      errorData.push('Czas trwania jest równy 0');
    
    }
      let testName = testType.value.find(item=> item.value == selectedData.value.id);
 
      let data = {
        "title": testName.text,
        "description": desc.value,
        "place": place.value,
     
        "schemaId": testTypeSelected.value,
        "startDate": new Date(date.value),
        "endDate": new Date(addMinutes(date.value, hour.value * 60 + minutes.value )),
        "measurements": [

        ]
      }
      data.measurements = [];



      for (const [key, val] of Object.entries(testsFields.value)) {
        
          
              // check if the passed value is a number
              if(typeof Number(val) == 'number' && !isNaN(Number(val))){
              } else {
                errorData.push('Wyniki muszą być wartością liczbową');
              
              }

              // if(!val.length){
              //   errorData.push('Pole z wynikiem jest puste');
              //   error = true;
              // }

          data.measurements.push({metricId: Number(key), value: Number(val), unitId: Number(testsUnits.value[key])});
      
      }
      if(!data.measurements.length){
  
                errorData.push('Brak uzupełnionych wyników');
             
      }
      if(errorData.length){
        emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z zapisem badania',
                  data: errorData,
                  visible: true,
              } );
      }else{
        await axios.put(`/medical-test-results/${route.params.id}`,data)
        .then(response => {
          emit('alert',{
                  type: 'success',
                  title: 'Badanie zostało dodane do systemu!',
                  visible: true,
                  autoClose: true,
                  data: []
                } );
          router.push({ path: '/kalendarz', query: { date: format(date.value, 'yyyy-MM-dd')}} );

        

        })
        .catch(err => {
        
            emit('alert',{
                  type: 'error',
                  title: 'Wystąpił problem z zapisem badania',
                  data: [err.response.data.error.message],
                  visible: true,
              } );
        });
      }
    
   }
    return {hour,minutes,footerHeight,desc,onFormSubmit,testsFields,testsUnits,place,placeData,date,rules,testType,testData,testTypeSelected,selectedData,onChangePlace};
  },
}
</script>


<style lang="scss" >
  .medical-test{

    display: flex;
    margin-left: -30px;
    margin-right: -30px;
    flex-wrap: wrap;;
    align-items: stretch;
    // .box{
    //   height: 100%;
    // }
    >*{
      padding-left: 30px;
      padding-right: 30px;
    }

    &__left{
      width: 40%;
    }

    &__right{
      width: 60%;
     
        // .box__content{
        //   margin-right: -20px;
        //   margin-left: -20px;

        //   display: flex;

        //   >*{
        //     padding-left: 20px;
        //     padding-right: 20px;
            
        //     &:last-child{
        //       width: 100%;
        //       .form-field{
        //         &:first-child{
        //           label{
        //             margin-top: 0;
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
      
    }

   @media(max-width: $xl){
    &__right, &__left{
      width: 100%;
    }
   }
  }

</style>