<template>
   <div class="date-selector-wrapper">
      <button @click.prevent="toggleOpenDateSelector" class="btn btn--secondary date-selector-wrapper__button" :class="{'btn--active': dateSelectorOpen}">{{buttonText ? buttonText : 'Zmień datę'}}</button>
      <div class="date-selector-wrapper__field" v-show="dateSelectorOpen">
        <VDatePicker ref="calendarPicker"  :min-date="minDate ? minDate : null" :max-date="maxDate ? maxDate : null"  trim-weeks  :is-range="isRange != 'undefined' ? isRange : true" title-position="left" borderless  v-model="range"/>
      </div>
    </div>
</template>

<script>
import { ref,watch } from 'vue';
export default {
  name: 'DateToggle',
  props: {
    modelValue: Object,
    minDate: Date,
    maxDate: Date,
    isRange: Boolean,
    buttonText: String
  },
  emit: ['update:modelValue'],
  setup(props, {emit}){
    let range = ref(props.modelValue);
    
    watch(range, newVal => {
      emit('update:modelValue', newVal);
    });
    watch(() => props.modelValue, (newVal ) => {
      range.value = newVal
    });
    let dateSelectorOpen = ref(false);
    function toggleOpenDateSelector(){
      dateSelectorOpen.value = !dateSelectorOpen.value;
    }
    return {toggleOpenDateSelector,dateSelectorOpen,range};
  }
}
</script>
