<template>

  <div class="t-radio" :class="className" v-show="typeof value !== 'undefined'">
      <input type="radio" :checked="checked" :name="name" :id="id" @change="onChange"   :class="{isLarge: typeof isLarge != 'undefined' ? isLarge : false}"  />
      <label :for="id">
        {{ text }}
      </label>
      
  </div>
</template>

<script>
import {watch,ref,onMounted } from 'vue';
import uniqid from 'uniqid';
export default {
  name: 'TRadio',
  props: {
    name: String,
    value: [String, Number],
    text: String,
    className: String,
    isLarge: Boolean,
    checked: Boolean,
  },
  emit: ['onChange'],
  setup(props, {emit} ){
    let data = ref();
    let id = uniqid();

    if(props.checked){
      emit('onChange', {checked: true, value: props.value});
    }

    function onChange(evt){
      
     emit('onChange', {checked: evt.target.checked, value: props.value});
    
    }


    return {data,id,onChange};
  },

  // methods: {
  //   onChange(e){
  //     console.log(e.target.value);
  //   }
  // }
}
</script>


<style lang="scss" scoped>

.t-radio{
  margin-bottom: 10px;
  input{
   
    opacity: 0;
    display: none;
    ~label{
      position: relative;
      padding-left: 1.8em;
      cursor: pointer;
      font-size: 14px;
      display: inline-block;
      &::before{
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 1.2em;
        height: 1.2em;
        border: 1px solid $border_color;
        display: inline-block;
        border-radius: 50%;
        
      }
    }

    &.isLarge{
      ~label{
        font-size: 16px;
        padding-left: 2.2em;
        // margin-bottom: 20px;
        &::before{
          width: 1.7em;
          height: 1.7em;
          top: 3px;
        }
      }
    }
    &:checked{
      ~label{
        &::before{
          background-image: url('@/assets/radio.svg');
          background-size: 60%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
 
  }
  
}
  
</style>