import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import axios from 'axios';

// import the package
import VueAwesomePaginate from "vue-awesome-paginate";

// import the necessary css file
import "vue-awesome-paginate/dist/style.css";




// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
function setupAxios({ app, router }) {
  const API_URL = 'https://app.hily.pl/api/v1';
  // const API_URL = 'https://hily.taureca.com/api/v1';

  const API_VERSION = '';

  const AUTH_APP_ID = 'abcdefg';
  const APP_INFO = 222;

  const baseURL = API_URL + API_VERSION
  const api = axios.create({
    baseURL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'responseType': 'stream'
    }
  })

  // Add a request interceptor
  api.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if(token){
        config.headers['Authorization'] = 'Bearer ' + token
    }

    config.headers['auth-app-id'] = AUTH_APP_ID
    config.headers['app-info'] = APP_INFO
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
   
    const notProtectedRoutes = ['/users/signup', '/auth/signin']
    const response = error.response
    const path = response.config.url
    const statusCode = response.status
   
    if (notProtectedRoutes.includes(path) == false) {
     
      switch (statusCode) {
        case 401:
        case 403:
        //   Notify.create({
        //     position: 'top',
        //     timeout: '1000',
        //     type: 'warning',
        //     message: 'Ta akcja wymaga ponownego logowania'
        //   })
          localStorage.removeItem('token');
          localStorage.removeItem('registerToken');
          localStorage.removeItem('resetPassToken');
          localStorage.removeItem('isAdmin');
          router.push({ path: '/logowanie', replace: true });
      }
    }



    return Promise.reject(error);
  });

  return api
}

let api;

   api = setupAxios({ app, router })


  createApp(App).provide('axios', api).use(router).use(VCalendar, {}).use(VueAwesomePaginate).mount('#app')



