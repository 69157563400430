<template>

  <div @click="onClick" class="calendar__day__event" 
    :class="`calendar__day__event--${type}`,{
      'calendar__day__event--not-active': needActive && !active
    }"  
    :style="{top: position + 'px', height: size + 'px'}">
      {{typeName}},<br>
      <b>{{desc}}</b>
                
      <span class="calendar__day__event__hour">
        {{timeStart}} - {{timeEnd}}
      </span>
  </div>              
      
</template>

<script>

export default {
  name: 'CalendarEvent',
  props: {
    type: String,
    typeName: String,
    desc: String,
    timeStart: String,
    timeEnd: String,
    position: Number,
    size: Number,
    id:String,
    needActive: Boolean,
    active: Boolean,
  },
  emit: ['onFocus'],
  setup(props, {emit}){
    
    function onClick(){
      emit('onFocus', {id: props.id, type: props.type});
    }
    return {onClick};
  }
}
</script>


<style lang="scss" scoped>

</style>