<template>
     <div class="chart-legend" >
        <ChartItem v-if="!emptyMedicalTestData && datasets.length" :title="datasets[0].label" :color="datasets[0].borderColor" />
        <ChartItem  v-show="!emptyDrugIntakesData"   :key="index" v-for="(item, index) in Object.entries(drugsColorsData)" :title="`Lek: ${drugNames[item[0]]}`" :color="item[1]" />
        
      </div>
      <div class="chart-wrapper">
        <span class="chart-wrapper__empty-text" v-if="emptyMedicalTestData && emptyDrugIntakesData">Brak danych w tym zakresie</span>
        <div class="chart-content" :class="{'chart-wrapper__blur': emptyMedicalTestData && emptyDrugIntakesData}">
          <LineChart ref="chartRef" :chartData="testData" :options="options" @click="test" :styles="{height: chartHeight+'px'}"/>
        </div>
      </div>
      

      <div class="chart-stats" v-show="false">
        <ChartStat title="Przyjmowanie leku" value="12" unit="dni" valueColor="#82e8bb" bg="#dff2f7" border="#b1d0f5" />
        <ChartStat title="Skuteczność" value="85" unit="%" valueColor="#82e8bb" bg="#dff2f7" border="#b1d0f5" />
        <ChartStat title="Najniższa dawka" value="10" unit="mIU/l" valueColor="#f36767" bg="#f7dada" border="#f6aeae" />
        <ChartStat title="Najwyższa dawka" value="50" unit="mIU/l" valueColor="#82e8bb" bg="#dff2f7" border="#b1d0f5" />
      </div>
      <Sidebox ref="sidebox" type="chart" :data="activeChartData" :open="isOpenSidebox"  @onExit="onExitSidebox" />
</template>

<script>
import { shuffle } from 'lodash';
import {ref, watch} from 'vue';
import { format, subDays,addDays,differenceInCalendarDays,set,add  } from 'date-fns';
import { useRouter, useRoute } from 'vue-router';
import ChartStat from '@/components/ChartStat.vue';
import ChartItem from '@/components/ChartItem.vue';
import Sidebox from '@/components/Sidebox.vue';
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
Chart.defaults.font.family = "Poppins";
// Chart.defaults.animation = false;

Chart.defaults.font.size = 15;
Chart.defaults.color= '#8b8b8b';
Chart.defaults.scale.grid.color= '#ededed';
 Chart.defaults.scale.border.color= '#ededed';


export default {
  name: 'CustomChart',
  props: {
    title: String,
    data: Object,
    drugDifference: Object,
    drugNames: Object,
    range: Object,
    emptyDrugIntakesData: Boolean,
    emptyMedicalTestData: Boolean,
  },
  components: {
    LineChart,ChartStat,ChartItem,Sidebox
  },
  setup(props){
    const chartRef = ref(null);
    let isOpenSidebox = ref(false);
   const sidebox = ref(null);
   const route = useRoute();
   let drugsIdPosition = ref({});
   const isSetDate = typeof route.query.date != 'undefined';
    
   const chartHeight = ref(  screen.width > 768 ? 600 : 400)
  
   function setLabels(range){
      let len = differenceInCalendarDays( range.end,range.start);
      let labels = [];
      let labelFormat =  parseInt(format(range.start, 'Y')) != parseInt(format(range.end, 'Y')) ? 'dd.MM.Y' : 'dd.MM' ;
      for(let i = 0 ; i <= len; i++){
        let date = addDays(range.start, i);
        labels.push(format(new Date(date), labelFormat));
      }

      testData.value.labels = labels;
   }
   let activeChartId = ref(null);
   let activeChartType = ref(null);
   let activeChartData = ref(null);
    const medicineColor = "#82e8bb";
    const colorArray = ["#65b0f0","#e63946","#354f52","#f72585","#fb8500","#000814","#023047",];
    let colorCount = 0;
    let currentAxis = 0;
    let axis = {
      y: {
        min: 0,
        max: 0,
      },
      y1: {
        min: 0,
        max: 0,
      }
    };


    let datasets = ref([]);
   let drugsColorsData = ref({});
    let testData = ref({
      labels: ['15.01', '16.01', '17.01', '18.01', '19.01','20.01', '21.01', '22.01', '23.01', '21.01', '22.01', '23.01'],
    
      // datasets: [datasets[1]]
      datasets: datasets.value
    });
    setLabels(props.range);
   watch(()=> props.range, newRange => {
   
    setLabels(newRange);
   })
    watch(()=> props.data, newVal =>{
      colorCount = 0;
   
      let tempDatasets = [];
      if(newVal.length){
        newVal.forEach(element => {
          let tempData ={};
          tempData.borderWidth =4;
          tempData.label = element.name;
          
          tempData.data = element.data;
        
        
      
        
          tempData.yAxisID = element.axis;
          let axisToleration ={
            y: 10,
            y1: 10
          };
          let maxData = Math.max(...tempData.data) ;
          // maxData = maxData > 0 ?  maxData + (maxData / axisToleration[element.axis]) : maxData - (maxData / axisToleration[element.axis]);
          let minData =  Math.min.apply(null, tempData.data.filter(Boolean)) ;
          // minData = minData < 0 ?  minData + (minData / (axisToleration[element.axis] / 2) ) : minData - (minData / (axisToleration[element.axis] / 2));

          axis[element.axis].max =  parseInt(maxData + 1);
          axis[element.axis].min =   parseInt(minData - 1);
        

          if(element.id == 'medicalTest'){
          tempData.borderColor = medicineColor;
          tempData.backgroundColor = medicineColor;
          }
          else{
            tempData.borderColor = colorArray[colorCount];
            tempData.backgroundColor = colorArray[colorCount];
            colorCount++;
          }

          
          if(element.id == 'drugsIntakes'){
           
            let drugsIds = [...new Set(props.drugDifference)].filter(item => item !== null);
            let drugsColors = {};
            let tempdrugsIdPosition = {};
            let tempdrugsLine = {};
             colorCount = 0;
            drugsIds.forEach(item =>{
          
              drugsColors[item] = colorArray[colorCount];
              tempdrugsLine[item] = [];
              tempdrugsIdPosition[item] = [];
              colorCount++;
            });

            drugsColorsData.value = drugsColors;

            props.drugDifference.forEach((item,index) => {
                if(item !== null){
                  tempdrugsIdPosition[item].push(index);
                  tempdrugsLine[item].push(index);
                }else{
                  
                  if(index > 0){
                    let tempIndex = index ;

                    tempIndex--;
                    
                    while(tempIndex > 0 && typeof props.drugDifference[tempIndex] != 'undefined'){
                      if( props.drugDifference[tempIndex] != null){
                        tempdrugsLine[props.drugDifference[tempIndex]].push(index);
                        break;
                      }
                    
                      tempIndex--;
                    }
                    
                  }
                }
                

              });

              drugsIdPosition.value = tempdrugsIdPosition;

              tempData.segment = {
                borderColor: (ctx) => {
                  const p0 = ctx.p0.parsed.x;

                  for (const [key, val] of Object.entries(tempdrugsLine)) {
                      let value = p0 == 0 || p0 == 1 ? p0 : p0-1;
                    
                      if(val.includes(p0) ){
                    
                        return drugsColors[key];
                      }
                
                  }
                
                },


              };
              let pointColors = tempData.data.map((item,index) =>{
                for (const [key, val] of Object.entries(tempdrugsLine)) {
                      let value = index;
                    
                      if(val.includes(value) ){
                    
                        return drugsColors[key];
                      }
                
                }

              });

            
             

              tempData.pointBackgroundColor = pointColors 
              tempData.pointBorderColor =  pointColors;
          
            
          }
          tempDatasets.push(tempData);
        });
      }else{
     
        tempDatasets =  [null];
      }
   

        let tempOptions = options.value;
       
     
          if(!props.emptyDrugIntakesData){

            tempOptions.scales['y1'] = {
          
                type: 'linear',
                position: 'right',
                max: axis.y1.max ,
                min: axis.y1.min ,
                borderWidth: 5,
                stacked: true,
                spanGaps: true,
                title:{
                  display: true,
                  text: '+Podanie IgG',
                  padding: 10,
                  color: '#393939',
                  font: {
                        size: 17,
                        weight: '700'
                    },
                },
                ticks: {
               
                  padding: 20,
                    font: {
                        size: 15,
                    },
                }
              };
         
          }else{
              //  tempOptions.scales['y1'].display = false;;
              //  tempOptions.scales['y1'].ticks.padding = 0;
              //  tempOptions.scales['y1'].max =0;
               
              //  tempOptions.scales['y1'].min =0;

              tempOptions.scales['y1'] = {
                display: false,
                ticks :{
                  padding: 0
                },
                min: 0,
                max: 0,
              }
          }

          if(!props.emptyMedicalTestData){
            tempOptions.scales['x'].ticks.padding = 40;
      
           tempOptions.scales['y'] = {
                  type: 'linear',
                  position: 'left',
                  spanGaps: true,
                  borderWidth: 5,
                  max: axis.y.max ,
                  min: axis.y.min ,
                  title:{
                    display: true,
                    text: '+Wynik IgG',
                    padding: 10,
                    color: '#393939',
                    font: {
                        size: 17,
                        weight: '700'
                    },
                  },
                  ticks: {
                    padding: 20,
                      font: {
                          size: 15,
                      },
                  
                  }
              };
          }else{
                // tempOptions.scales['y'].display = false;;
                // tempOptions.scales['y'].ticks.padding = 0;
                tempOptions.scales['x'].ticks.padding = 0;
              
                // tempOptions.scales['y'].max =0;
                // tempOptions.scales['y'].min =0;

                tempOptions.scales['y'] = {
                  display: false,
                  ticks :{
                    padding: 0
                  },
                  min: 0,
                  max: 0,
                };
          }
         
          datasets.value =  tempDatasets;
          
          testData.value = {
            labels: testData.value.labels,
            datasets:   datasets.value
          };
          options.value = tempOptions;
            
         
    });

    function chartUpdate(){
      console.log(  chartRef.value);
      chartRef.value.update();
    }
    function handleChartRender(chart) {
      console.log(chart);
    }
    let options = ref({
      // spanGaps: true,
      // onClick: onClickOnChartValue ,
      
    
      spanGaps: true,
      legend: {
        display: false
      },
        scales: {
          x:{
            ticks:{
              maxTicksLimit: 9,
              autoSkip: true,
              padding: 40,
            }
            
          },
       
        },
        
        plugins: {
          tooltip: {
            callbacks: {
              label: function (t, d) {
         
                if(t.dataset.yAxisID == 'y1'){
              
                  const xVal = t.dataIndex;
                 
                  if(Object.keys(drugsIdPosition.value).length){
                    for (const [key, val] of Object.entries(drugsIdPosition.value)) {
                
                      if(val.includes(xVal)){
                       
                        return '  ' + props.drugNames[key] + ': ' + t.formattedValue +' g';
                      }
                    }
                  }
                  
            
                }else{

                  return '  ' + t.dataset.label + ': ' + t.formattedValue + ' g/L';
                }
                 
            
                
                }
              }
          },
          
          legend: {
            display: false
          }
        }
   
    });
  

    function onClickOnChartValue(event, elements, chart) {



      if (elements[0]) {            
        const i = elements[0].index;
        const datasetIndex = elements[0].datasetIndex;
      // console.log('elements',chart.data.datasets[datasetIndex]);
        //  alert(chart.data.labels[i] + ': ' + chart.data.datasets[datasetIndex].data[i]);
        
        isOpenSidebox.value = true;
        activeChartData.value = chart.data.labels[i];
      }
    }

    function onExitSidebox(){
      isOpenSidebox.value = false;
      activeChartId.value = null;
      setTimeout(() => {
        activeChartType.value = null;
      }, 500);
      
    }
    return {chartUpdate,chartRef,chartHeight, testData,options,datasets,onExitSidebox,isOpenSidebox,activeChartId, activeChartData,activeChartType, sidebox,drugsIdPosition,drugsColorsData};
  }
}
</script>

<style lang="scss" scoped>

  .chart-legend{
    display: flex;
    flex-wrap: wrap;
  }
  .chart-stats{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-right: -10px;
    margin-left: -10px;

    .chart-stat-wrapper{
      width: 25%;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;

      @media(max-width: 1400px){
        width: 50%;
      }

      @media(max-width: 600px){
        width: 100%;
      }
    }
  }

  .chart-legend{
    display: flex;
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: 30px;
    >*{
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .chart-wrapper{
    position: relative;
    overflow-y: auto;
    &__blur{
      filter: blur(2px);
    }
   
    &__empty-text{
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%,20%);
      color: white;
      font-size: 12px;
      background-color: $secondary_color;
      padding: 10px;
      z-index: 99991;
    }
  }

  .chart-content{
   
    @media(max-width: $md){
      width: 600px;
    }
  }
</style>
